export default {
	en: {
		id: "ID",
		name: "Name",
		countryCode: "Country code",
		allowedForAddress: "Allowed for address",
		createCountry: "Create a country",
		editCountry: "Edit a country",
	},
	fr: {
		id: "ID",
		name: "Nom",
		countryCode: "Code pays",
		allowedForAddress: "Autorisé pour adresse",
		createCountry: "Créer un pays",
		editCountry: "Modifier un pays",
	},
	spa: {
		id: "ID",
		name: "Nombre",
		countryCode: "Código de país",
		allowedForAddress: "Permitido para dirección",
		createCountry: "Crear un país",
		editCountry: "Editar un país",
	},
	it: {
		id: "ID",
		name: "Nome",
		countryCode: "Codice paese",
		allowedForAddress: "Consentito per indirizzo",
		createCountry: "Crea un paese",
		editCountry: "Modifica un paese",
	},
}