import { Axios } from "./config.js";
import clean from "clean-deep";

export default class CoreCatalogService {
  async getCollection({
    params = {
      page: 1,
      limit: 25,
      category: "",
      taxFamily: "",
      description: "",
      cn8: ""
    } } = {}) {
    return await Axios.get("admin/core-catalog/list", { params: clean(params) });
  }

  async get({ cid } = {}) {
    return await Axios.get(`core-catalog/${cid}`)
  }

  async search({ query = "" } = {}) {
    return await Axios.get(`core-catalog/search/${query}`);
  }

  async create({
    user = false,
    body = {
      cn8: "",
      description: "",
      abvMin: 1,
      abvMax: 1,
      prodInEu: false,
      pressured: false,
      container: "",
      colour: "",
      variety: "",
      category: ""
    },
  } = {}) {
    const { description, abvMax, abvMin, prodInEu, pressured, container, colour, variety, category } = body

    if (user) {
      // with out cn8

      return await Axios.post("core-catalog/create", {
        description,
        abvMin,
        abvMax,
        prodInEu,
        pressured,
        container,
        colour,
        variety,
        category,
      })
    }

    return await Axios.post("admin/core-catalog/create", body);
  }

  async update({
    pid = "",
    body = {
      cn8: "",
      description: "",
      abvMin: 1,
      abvMax: 1,
      prodInEu: false,
      pressured: false,
      container: "",
      colour: "",
      variety: "",
      categoryName: ""
    },
  } = {}) {

    return await Axios.patch(`admin/core-catalog/${pid}/update`, body);
  }

  async match({
    body = {
      description: "",
      abvMin: 1,
      abvMax: 1,
      prodInEu: false,
      pressured: false,
      container: "",
      colour: "",
      variety: "",
      category: ""
    },
  } = {}) {
    const { description, abvMax, abvMin, prodInEu, pressured, container, colour, variety, category } = body
    return await Axios.post("/core-catalog/match", {
      description,
      abvMin,
      abvMax,
      prodInEu,
      pressured,
      container,
      colour,
      variety,
      category
    });
  }

  async getDescriptionCollection() {
    return await Axios.get("helper/core-catalog-descriptions-list")
  }

  async remove(id) {
    return await Axios.delete(`admin/core-catalog/${id}/delete`)
  }
}
