import { IState, CustomerMutationTypes } from "./types";

export const mutations = {
  [CustomerMutationTypes.SetCustomerCollection](state, { items = [], page = 1, limit = 25, total = 0 } = {}) {
    state.customers = items;
    state.page = page,
      state.limit = limit,
      state.total = total
  },

  [CustomerMutationTypes.SetCustomer](state, { data = {} } = {}) {
    state.customer = data;
  },

  [CustomerMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [CustomerMutationTypes.SetCreationState](state, newCreation) {
    state.creation = newCreation;
  },

  [CustomerMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  }
}