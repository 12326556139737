export default {
	en: {
		firstName: "First name",
		lastName: "Last name",
		email: "Email",
		phone: "Phone",
		tin: "TAX identification number",
		businessName: "Business name",

		id: "ID",
		customer: "Customer",
		defBillAddr: "Default billing address",
		defShipAddr: "Default shipping address",
		actions: "Actions",
		createCustomer: "Create a customer",
		persInform: "Personal information",
		chooseAddr: "Choose address",
		createAddr: "Create address",
		editAddr: "Edit address",
		customersImport: "Customers import",
		editCustomer: "Edit a customer",
		shippingAndBilling: "Shipping and billing",
		shipping: "Shipping",
		billing: "Billing",
		createOrChooseAddress: "Create or choose address",
		step1: "Step 1 - personal information",
		step2: "Step 2 - default addresses creation",
		shipAndBillTheSame: "Shipping and billing address are the same",
		defaultShipAndBillAddr: "Defauilt shipping and billing address",
		doYouWantSaveContacts: "Do you want to save the contact info and address(s) of this customer?",
		pleaseFillInInformation: "Please fill-in the personal information and click \"Create\" button below in order to create a customer",
		pleaseChooseFromTheOptions: "Please choose from the options below: to create the same address for both shipping and billing or to proceed separately"
	},
	fr: {
		firstName: "Prénom",
		lastName: "Nom de famille",
		email: "Courriel",
		phone: "Téléphone",
		tin: "Numéro d'identification FISCALE",
		businessName: "Nom de l'entreprise",
		
		id: "ID",
		customer: "Client",
		defBillAddr: "Adresse de fact. par défaut",
		defShipAddr: "Adresse de livraison par défaut",
		actions: "Actions",
		createCustomer: "Creer",
		persInform: "Information personnelle",
		chooseAddr: "Sélectionner adresse",
		createAddr: "Créer une adresse",
		editAddr: "Editer une adresse",
		type: "Type",
		customersImport: "Importer clients",
		editCustomer: "Editer client",
		shippingAndBilling: "Facturation et livraison",
		shipping: "Livraison",
		billing: "Facturation",
		createOrChooseAddress: "Créer ou choisir une adresse",
		step1: "Étape 1 - Information personnelle",
		step2: "Étape 2 - Création d'adresses par défaut",
		shipAndBillTheSame: "L'adresse de livraison et de facturation sont les mêmes",
		defaultShipAndBillAddr: "Adresse de livraison et de facturation par défaut",
		doYouWantSaveContacts: "Voulez-vous enregistrer les coordonnées et les adresses de ce client?",
		pleaseFillInInformation: "Veuillez remplir les informations personnelles et cliquer sur le bouton \"Créer\" ci-dessous afin de créer un client",
		pleaseChooseFromTheOptions: "Veuillez choisir parmi les options ci-dessous: pour créer la même adresse pour l'expédition et la facturation ou pour procéder séparément"
	},
	spa: {
		firstName: "Nombre",
		lastName: "Apellido",
		phone: "Teléfono",
		email: "Correo electrónico",
		tin: "Número de identificación FISCAL",
		businessName: "Nombre de la empresa",

		id: "ID",
		customer: "Cliente",
		defBillAddr: "Dirreción de factruración por defecto",
		defShipAddr: "Direccion de entrega por defecto",
		actions: "Acciones",
		createCustomer: "Crear un cliente",
		persInform: "Información personal",
		chooseAddr: "Elegir una dirección",
		createAddr: "Crear una dirección",
		addrName: "Dirección",
		editAddr: "Editar dirección",
		customersImport: "Importación de clientes",
		editCustomer: "Editar cliente",
		shippingAndBilling: "Facturación y envios",
		shipping: "Entrega",
		billing: "Facturación",
		createOrChooseAddress: "Crear o elegir dirección",
		step1: "Paso 1 - información personal",
		step2: "Paso 2 - creación de direcciones predeterminadas",
		shipAndBillTheSame: "La dirección de envío y facturación es la misma",
		defaultShipAndBillAddr: "Dirección de envío y facturación predeterminada",
		doYouWantSaveContacts: "¿Quieres guardar la información de contacto y la(s) dirección (es) de este cliente?",
		pleaseFillInInformation: "Complete la información personal y haga clic en el botón \"Crear\" a continuación para crear un cliente",
		pleaseChooseFromTheOptions: "Elija entre las siguientes opciones: para crear la misma dirección para el envío y la facturación o para proceder por separado"
	},
	it: {
		firstName: "Nome",
		lastName: "Cognome",
		email: "Email",
		phone: "Telefono",
		tin: "Codice FISCALE",
		businessName: "Nome commerciale",
		
		id: "ID",
		customer: "Cliente",
		defBillAddr: "Indirizzo di fatturazione predefinito",
		defShipAddr: "Indirizzo di consegna predefinito",
		actions: "Operazioni",
		createCustomer: "Crea un cliente",
		persInform: "Informazione personale",
		chooseAddr: "Seleziona un indirizzo",
		createAddr: "Crea un indirizzo",
		editAddr: "Modifica indirizzo",
		customersImport: "Importa clienti",
		editCustomer: "Modifica cliente",
		shippingAndBilling: "Fatturazione e invii",
		shipping: "Consegna",
		billing: "Fatturazione",
		createOrChooseAddress: "Crea o seleziona l'indirizzo",
		step1: "Passo 1 - informazione personale",
		step2: "Passo 2 - creazione indirizzi predefiniti",
		shipAndBillTheSame: "Indirizzo di spedizione e fatturazione sono gli stessi",
		defaultShipAndBillAddr: "Indirizzo di spedizione e fatturazione predefinito",
		doYouWantSaveContacts: "Vuoi salvare le informazioni di contatto e gli indirizzi di questo cliente?",
		pleaseFillInInformation: "Si prega di compilare le informazioni personali e fare clic sul pulsante \"Crea\" qui sotto per creare un cliente",
		pleaseChooseFromTheOptions: "Scegli tra le seguenti opzioni: per creare lo stesso indirizzo sia per la spedizione che per la fatturazione o per procedere separatamente"
	},
}