export const CustomerNamespace = "customer"

export const CustomerActionTypes = {
  GetCustomerCollection: "getCustomerCollection",
  GetCustomer: "getCustomer",
  SearchCustomerCollection: "searchCustomerCollection",
  CreateCustomer: "createCustomer",
  UpdateCustomer: "updateCustomer",
  RemoveCustomer: "removeCustomer",
  ClearState: "clearState"
}

export const CustomerMutationTypes = {
  SetCustomerCollection: "setCustomerCollection",
  SetCustomer: "setCustomer",
  SetCustomerReference: "setReference",
  SetLoadingState: "setLoadingState",
  SetCreationState: "setCreationState",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  creation: false,
  customers: [],
  customer: {},
  limit: 25,
  page: 1,
  total: 0
}

export const ICustomer = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  tin: "",
  businessName: "",
  billingAddressId: null,
  shippingAddressId: null,
  billingAddress: {},
  shippingAdress: {}
}