export default [
	{
		path: "/eda-vat-categories",
		name: "EdaVat",
		component: () => import("../views/Admin/Categories/CategoriesPage.vue"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},

]