import dayjs from "dayjs";
import { order } from "@/api";
import { i18n } from "@/plugins/i18n";

import { OrderActionTypes, OrderMutationTypes } from "./types";

export const actions = {
  async [OrderActionTypes.GetOrderCollection]({ dispatch, commit }, {
    sid = "",
    params = {},
    admin = false,
    root = false
  } = {}) {
    commit(OrderMutationTypes.SetLoadingState, { root })

    try {
      const { data } = await order.getCollection({
        sid,
        admin,
        params
      })

      commit(OrderMutationTypes.SetOrderCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(OrderMutationTypes.SetLoadingState, { root })
    }
  },

  async [OrderActionTypes.GetOrder]({ dispatch, commit }, { oid = "" } = {}) {
    try {
      const { data } = await order.get({ oid })
      data.approxDeliveryDate = dayjs(data.approxDeliveryDate).format("YYYY-MM-DD")
      commit(OrderMutationTypes.SetOrder, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [OrderActionTypes.CreateOrder]({ dispatch, commit }, { sid = "", body = {} } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });
    try {
      const { data } = await order.create({ sid, body })

      commit(OrderMutationTypes.SetOrder, { data })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        "Order created",
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [OrderActionTypes.UpdateOrder]({ dispatch, commit }, { oid = "", body = {} } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });

    try {
      const { data } = await order.update({ oid, body })

      commit(OrderMutationTypes.SetOrder, { data })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        "Order updated",
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [OrderActionTypes.GetOrderFile]({ dispatch, commit }, { oid = "", type = "saad" } = {}) {
    try {
      if (type === "saad") {
        const { data } = await order.getSaadFile({ oid })
        commit(OrderMutationTypes.SetOrderFile, { type, data })
      }

      if (type === "invoice") {
        const { data } = await order.getInvoiceFile({ oid })
        commit(OrderMutationTypes.SetOrderFile, { type, data })
      }

    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [OrderActionTypes.GenerateOrderDocuments]({ dispatch }, { oid = "" } = {}) {
    try {
      await order.generateDocuments({ oid })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [OrderActionTypes.CreateOrderPayment]({ dispatch, commit }, { oid = "" } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });

    try {
      const { data } = await order.getPayment({ oid })

      commit(OrderMutationTypes.SetOrderPayment, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [OrderActionTypes.GetOrderPaymentStatus]({ dispatch, commit }, { tid = "" } = {}) {
    try {
      const { data } = await order.getPaymentStatus({ tid })

      commit(OrderMutationTypes.SetOrderPaymentStatus, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [OrderActionTypes.GetOrderExcelDocument]({ dispatch, commit }, {
    params = {},
    root = false
  } = {}) {
    commit(OrderMutationTypes.SetLoadingState, { root });

    try {
      const { data } = await order.downloadExcel({ params });

      let type = "excel";
      commit(OrderMutationTypes.SetOrderFile, { type, data });

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.excelFileGenerated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [OrderActionTypes.UpdateOrderStatus]({ dispatch, commit }, { oid = "", status = "" } = {}) {
    try {
      await order.updateOrderStatus({ oid, status })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.base"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [OrderActionTypes.CalculateOrderPrice]({ dispatch }, { body = {} } = {}) {
    try {
      const { data } = await order.calculatePrice({ body });
      return data;
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }

    return null;
  },


  [OrderActionTypes.ClearState]({ commit }) {
    commit(OrderMutationTypes.ClearState)
  }
}