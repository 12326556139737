<template>
  <v-app>
    <v-container fluid>
      <Snackbar></Snackbar>
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    Snackbar: () => import("~ui/Snackbar/Snackbar"),
  },
};
</script>