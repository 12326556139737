import { IState, CountryMutationTypes } from "./types";

export const mutations = {
  [CountryMutationTypes.SetCountryCollection](state, { items, page, limit, total }) {
    state.countries = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [CountryMutationTypes.SetCountry](state, { data }) {
    state.country = data
  },

  [CountryMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [CountryMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  },

  [CountryMutationTypes.SetCountryAllowedForAddressCollection](state, { list }) {
    state.allowedForAddressItems = list
  }
}