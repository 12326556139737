export default [
	{
		path: "/administration",
		name: "AdministrationPage",
		component: () => import("../views/Admin/Administration/AdministrationPage"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		}
	},
]
