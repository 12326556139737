export default [
    {
        path: "/shops",
        component: () => import("@/views/Customer/Shops/ShopsPage.vue"),
        meta: {
            userRole: "ANY",
            layout: "Default",
            auth: true
        },
    },

]