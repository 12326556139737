export default {
	en: {
		id: "Order ID",
		status: "Status",
		paymentStatus: "Payment status",
		paymentData: {
			createdDate: "Payment date",
		},
		shippingCountry: "Shipping country",
		shippingMethod: "Shipping method",
		approxDeliveryDate: "Approx. delivery date",
		goodsTotal: "Goods total",
		totalPayment: "Total payment",
		vat: "VAT",
		customer: "Customer",
		shop: "Shop",
		user: "User",

		productsData: {
			reference: "Reference",
			productName: "Product name",
			productDescription: "Description",
			productListShortDescription: "Product short description",
			abv: "ALC. VOL %",
			litersPerUnit: "Liters per unit",
			weightOfTheUnit: "Weight of the unit (KG)",
			price: "Default unit price",
			eanCode: "EAN",
			coreCatalogDescription: "Description",
			coreCatalogCN8: "CN8",
			exciseType: "Excise type",
			exciseRate: "Excise rate",
			discoutablePrice: "Discountable price",
			numberOfBottles: "Number of bottles",
			totalBottlesPriceTaxExc: "Total bottles price (tax excluded)",
			exciseAmount: "Excise amount",
			eurotaxService: "Eurotax services",
			totalToPay: "Total to pay",
		},


		totalTaxServ: "Total tax & services",

		saad: "SAAD",
		invoice: "Invoice",
		createdAt: "Creation date",

		from: "From",
		to: "To",

		orderCreation: "Order creation",
		orderPayment: "Order payment",
		finishedOrder: "Finished order",

		search: "Search",
		carrier: "Carrier",
		chooseProduct: "Choose a product",
		generalInf: "General information",
		vatRate: "VAT rate",

		billingAddress: "Billing Address",
		shippingAddress: "Shipping Address",
		country: "Country",
		area: "Area",
		city: "City",
		phone: "Phone",
		address1: "Address 1",
		address2: "Address 2",
		zipcode: "ZIP Code",

		order_creation: "Order creation",
		awaiting_payment: "Awaiting payment",
		order_finished: "Order finished",
		cancelled: "Cancelled",
		error: "Error",
		refunded: "Refunded",

		dateMustBeGreater: "Date must be greater",
		noData: "No data available",
		taxFamilyNotExist: "Tax family not exist for current country.",
		shippingCountryAndTaxFamilyMismatch: "Shipping country and product tax family mismatch. Please contact support to make sure this specific product can be shipped to the customer's shipping address.",

		searchCustomerBy: "Search customer by",
		selectCustomer: "Select Customer",
		deliveryInfo: "Delivery info",
		deliveryDate: "Delivery date",
		searchProductBy: "Search product by",
		searchProduct: "Select Product",

		minimumOrderAmount: "Minimum order payment amount is 1€.",
		pleaseFillVatAndCity: "Please fill in the VAT number, City and Email mandatory fields in the settings of your shop",
		shippingCountryNotSet: "Shipping country code not found in the list. Check if shipping country is filled.",
		defaultTaxFamilyNotSet: "Default tax family not found. Cannot add product. Please contact support."
	},
	fr: {
		id: "Numéro de commande",
		status: "Etat",
		paymentStatus: "Etat paiement",
		paymentData: {
			createdDate: "Date paiement",
		},
		shippingCountry: "Pays de livraison",
		shippingMethod: "Méthode de livraison",
		approxDeliveryDate: "Date livr. Approx.",
		goodsTotal: "Biens au totall",
		totalPayment: "Paiment total",
		vat: "TVA",
		customer: "Client",
		shop: "Boutique",
		user: "Utilisateur",

		productsData: {
			reference: "Référence",
			productName: "Nom produit",
			productDescription: "Déscription",
			productListShortDescription: "Description résumée produit",
			abv: "Degré / Vol. %",
			litersPerUnit: "Litres / unité",
			weightOfTheUnit: "Poids de l'unité (Kg)",
			price: "Unité par défaut",
			eanCode: "EAN",
			coreCatalogDescription: "Déscription",
			coreCatalogCN8: "Nomenclature",
			exciseType: "Type accises",
			exciseRate: "Taux accises",
			discoutablePrice: "Prix modifiable",
			numberOfBottles: "Nº de bouteilles",
			totalBottlesPriceTaxExc: "Prix HT bouteilles",
			exciseAmount: "Montant accises",
			eurotaxService: "Service Eurotax",
			totalToPay: "À régler",
		},

		totalTaxServ: "Total taxes et droits, services",

		saad: "Doc. Accomp. Simplifié",
		invoice: "Facture",
		createdAt: "Date création",

		from: "De",
		to: "À",

		orderCreation: "Création de commande",
		orderPayment: "Paiment de commande",
		finishedOrder: "Commende finalisée",
		search: "Recherche",
		carrier: "Transporteur",
		chooseProduct: "Sélectionner produit",
		generalInf: "Information générale",
		vatRate: "Taux TVA",

		billingAddress: "Adresse de facturation",
		shippingAddress: "Adresse de livraison",
		country: "Pays",
		area: "Région",
		city: "Ville",
		phone: "Téléphone",
		address1: "Adresse 1",
		address2: "Adresse 2",
		zipcode: "Code postal",

		order_creation: "Création de commande",
		awaiting_payment: "En attente de paiment",
		order_finished: "Commande finalisée",
		cancelled: "Annulé",
		error: "Erreur",
		refunded: "Remboursé",

		dateMustBeGreater: "Date must be greater",
		noData: "Absence de données",
		taxFamilyNotExist: "Tax family not exist for current country.",
		shippingCountryAndTaxFamilyMismatch: "Incompatibilité entre le pays d'expédition et la famille de taxes sur les produits. Veuillez contacter le support pour vous assurer que ce produit spécifique peut être expédié à l'adresse de livraison du client.",

		searchCustomerBy: "Search customer by",
		selectCustomer: "Select Customer",
		deliveryInfo: "Delivery info",
		deliveryDate: "Delivery date",
		searchProductBy: "Search product by",
		searchProduct: "Search product",

		minimumOrderAmount: "Le montant minimum de paiement de la commande est de 1€.",
		pleaseFillVatAndCity: "Veuillez remplir les champs obligatoires Numéro de TVA, Ville et Email dans les paramètres de votre boutique",
		shippingCountryNotSet: "Code pays de livraison non trouvé dans la liste. Vérifiez si le pays de livraison est rempli.",
		defaultTaxFamilyNotSet: "Famille de taxes par défaut non trouvée. Impossible d'ajouter un produit. Veuillez contacter le support."

	},
	spa: {
		id: "Número de pedido",
		// orderId: "Número de pedido",

		status: "Estatus",
		paymentStatus: "Estado del pago",
		paymentData: {
			createdDate: "Fecha del pago",
		},
		shippingCountry: "País de envío",
		shippingMethod: "Método de envío",
		approxDeliveryDate: "Fecha de entrega approx.",
		goodsTotal: "Bienes (total)",
		totalPayment: "Pago total",
		vat: "IVA",
		customer: "Cliente",
		shop: "Tienda",
		user: "Usuario",

		productsData: {
			reference: "Referencia",
			productName: "Nombre del producto",
			productDescription: "Descripción",
			productListShortDescription: "Breve descripción del producto",
			abv: "Grado/ Vol %",
			litersPerUnit: "Litros por unidad",
			weightOfTheUnit: "Peso de la unidad (KG)",
			price: "Unidad por defecto",
			eanCode: "EAN",
			coreCatalogDescription: "Descripción",
			coreCatalogCN8: "Nomenclatura combinada",
			exciseType: "Tipo de IIEE de bebidas alcohólicas",
			exciseRate: "Tasa sobre el impuesto especial sobre bebidas alcohólicas",
			discoutablePrice: "Precio modifcable",
			numberOfBottles: "Número de botellas",
			totalBottlesPriceTaxExc: "Precio total de las botellas (IVA no incluido)",
			exciseAmount: "Importe del impuesto especial sobre bebidas alcohólicas",
			eurotaxService: "Servicios Eurotax",
			totalToPay: "Total a pagar",
		},

		totalTaxServ: "Total impuestos y servicios",

		saad: "DSA",
		invoice: "Factura",
		createdAt: "Fecha de creación",

		from: "De",
		to: "Para",
		orderCreation: "Creación de pedidos",
		orderPayment: "Orden de pago ",
		finishedOrder: "Pedido finalisado",
		chooseCustomer: "Elegir cliente",
		byName: "Por nombre",
		search: "Buscar",
		carrier: "Transportista",
		chooseProduct: "Elegir un producto",
		generalInf: "Información general",
		vatRate: "Tipo de IVA",

		billingAddress: "Dirección de facturación",
		shippingAddress: "Direccion de envío",
		country: "País",
		area: "Communidad",
		city: "Ciudad",
		phone: "Teléfono",
		address1: "Dirección 1",
		address2: "Dirección 2",
		zipcode: "Código postal",

		order_creation: "Creación de pedidos",
		awaiting_payment: "En espera de pago",
		order_finished: "Pedido finalisado",
		cancelled: "Cancelado",
		error: "Error",
		refunded: "Reintegro",

		dateMustBeGreater: "Date must be greater",
		noData: "Datos no disponibles",
		taxFamilyNotExist: "Tax family not exist for current country.",
		shippingCountryAndTaxFamilyMismatch: "Desajuste de la familia de impuestos del país de envío y del producto. Póngase en contacto con el servicio de asistencia para asegurarse de que este producto específico se pueda enviar a la dirección de envío del cliente.",

		searchCustomerBy: "Search customer by",
		selectCustomer: "Select Customer",
		deliveryInfo: "Delivery info",
		deliveryDate: "Delivery date",
		searchProductBy: "Search product by",
		searchProduct: "Search product",

		minimumOrderAmount: "El importe mínimo de pago del pedido es de 1€.",
		pleaseFillVatAndCity: "Por favor, rellene los campos obligatorios de número de IVA, Ciudad y Correo electrónico en la configuración de su tienda",
		shippingCountryNotSet: "Código de país de envío no encontrado en la lista. Compruebe si el país de envío está relleno.",
		defaultTaxFamilyNotSet: "Familia de impuestos por defecto no encontrada. No se puede añadir el producto. Por favor, póngase en contacto con el servicio de asistencia."
	},
	it: {
		id: "ID ordine",
		status: "Status",
		paymentStatus: "Stato del pagamento",
		paymentData: {
			createdDate: "Data di pagamento",
		},
		shippingCountry: "Paese di spedizione",
		shippingMethod: "Metodo di spedizione",
		approxDeliveryDate: "Data di consegna approx.",
		goodsTotal: "Totale dei beni",
		totalPayment: "Totale pagamento",
		vat: "IVA",
		customer: "Cliente",
		shop: "Negozio",
		user: "Utente",

		productsData: {
			reference: "Riferimento",
			productName: "Nome del prodotto",
			productDescription: "Descrizione",
			productListShortDescription: "Breve descrizione del prodotto",
			abv: "Alc. Vol. %",
			litersPerUnit: "Litri per unità",
			weightOfTheUnit: "Peso dell'unità (KG)",
			price: "Unità predefinita",
			eanCode: "EAN",
			coreCatalogDescription: "Descrizione",
			coreCatalogCN8: "CN8",
			exciseType: "Tipo di accisa",
			exciseRate: "Tassa accise",
			discoutablePrice: "Prezzo scontato",
			numberOfBottles: "Numero di bottiglie",
			totalBottlesPriceTaxExc: "Prezzo totale delle bottiglie (tasse escluse)",
			exciseAmount: "Importo accise",
			eurotaxService: "Servizi Eurotax",
			totalToPay: "Totale da pagare",
		},


		totalTaxServ: "Totale tasse e servizi",

		saad: "SAAD",
		invoice: "Fattura",
		createdAt: "Data di creazione",

		from: "Da",
		to: "A",

		orderCreation: "Effettua un ordine",
		orderPayment: "Pagamento ordine",
		finishedOrder: "Ordine completato",

		search: "Cerca",
		carrier: "Corriere",
		chooseProduct: "Seleziona un prodotto",
		generalInf: "Informazione generale",
		vatRate: "Tipo di IVA",

		billingAddress: "Indirizzo di fatturazione",
		shippingAddress: "Indirizzo di spedizione",
		country: "Paese",
		area: "Regione",
		city: "Città",
		phone: "Telefono",
		address1: "Indirizzo 1",
		address2: "Indirizzo 2",
		zipcode: "Codice postale",

		order_creation: "Effettua un ordine",
		awaiting_payment: "In attesa del pagamento",
		order_finished: "Ordini completati",
		cancelled: "Annullato",
		error: "Errore",
		refunded: "Rimborsato",

		dateMustBeGreater: "La data deve essere posteriore",
		noData: "Informazioni non disponibili",
		taxFamilyNotExist: "Non esiste nessun nucleo familiare per questo paese",
		shippingCountryAndTaxFamilyMismatch: "Paese di spedizione e prodotto fiscale famiglia disadattamento. Si prega di contattare l'assistenza per assicurarsi che questo prodotto specifico possa essere spedito all'indirizzo di spedizione del cliente.",

		searchCustomerBy: "Cerca cliente per ",
		selectCustomer: "Seleziona cliente",
		deliveryInfo: "Informazioni di consegna",
		deliveryDate: "Data di consegna",
		searchProductBy: "Cerca prodotto per",
		searchProduct: "Cerca prodotto",

		minimumOrderAmount: "L'importo minimo per il pagamento dell'ordine è di 1€.",
		pleaseFillVatAndCity: "Si prega di compilare il numero di partita IVA, Città ed e-mail campi obbligatori nelle impostazioni del tuo negozio",
		shippingCountryNotSet: "Codice paese di spedizione non trovato nell'elenco. Controlla se il paese di spedizione è compilato.",
		defaultTaxFamilyNotSet: "Famiglia fiscale predefinita non trovata. Impossibile aggiungere il prodotto. Si prega di contattare l'assistenza."
	},
}