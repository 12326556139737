import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);


const vuetify = new Vuetify({
theme: {
	themes: {
	light: {
		primary: "#f7303f",
		secondary: "#474747",
		link: "#1976d2"
	},
	},
},
})
  
export default vuetify
