export default {
  en: {
    error: {
      base: "Error",
      incorrectEmailOrPassword: "Incorrect email or password",
      somethingWentWrong: "Something went wrong",
      fileDownload: "File download error",
      fileSending: "File sending error",
      onlyAnEmptyCanBeRemoved: "Only an empty shop can be removed",
      coreCatalogTypeCantBeRemoved: "Sorry, the core catalog type can't be removed. It has assigned products",
      unknownRole: "Unknown Role"
    },
    success: {
      base: "Success",
      passwordReset: "Successful password reset!",
      passwordForgot:
        " We sent you the confirmation email. Please confirm the reset",
      update: "Update successful",
      delete: "Delete successful",
      download: "Download successful",
      sendToManager: "Your quote has been sent to your advisor",
      addressCreated: "Address successfully created",
      addressUpdated: "Address successfully updated",
      userCreated: "User successfully created",
      userUpdated: "User successfully updated",
      userEdited: "User successfully edited",
      coreCatalogCreated: "Core catalog successfully created",
      coreCatalogUpdated: "Core catalog successfully updated",
      matchFound: "Match found",
      typeDeleteSuccess: "Type delete success",
      categoryCreated: "Category successfully created",
      categoryUpdated: "Category successfully updated",
      categoryDeleted: "Category successfully deleted",
      customerCreated: "Customer successfully created",
      pleaseClickNext: ", please click the \"Next\" button below to proceed with further steps.",
      customerUpdated: "Customer successfully updated",
      customerDeleted: "Customer successfully deleted",
      familyTaxCreated: "Family-Tax successfully created",
      familyTaxDeleted: "Family-Tax successfully deleted",
      importTaxCreated: "Import-Tax successfully created",
      importTaxDeleted: "Import-Tax successfully deleted",
      productCreated: "Product successfully created",
      productUpdated: "Product successfully updated",
      excelFileGenerated: "Excel file successfully generated",
      defEurotaxPriceChagned: "Default Eurotax service price successfully changed",
      shopDeleted: "Shop deleted successfully",
      shopCreated: "Shop successfully created",
      shopEdited: "Shop successfully edited",
      countryCreated: "Country successfully created",
      countryUpdated: "Country successfully updated",
      countryEdited: "Country successfully edited",
    },
  },
  fr: {
    error: {
      base: "Erreur",
      incorrectEmailOrPassword: "Email ou mot de passe incorrect",
      somethingWentWrong: "Something went wrong",
      fileDownload: "Error de download",
      fileSending: "Erreur lors de l'envoi du fichier",
      onlyAnEmptyCanBeRemoved: "Seule une Boutique vide peut être eliminée",
      coreCatalogTypeCantBeRemoved: "Oups, le core catalog ne peut pas Être éliminé. Il a des produits assignés",
      unknownRole: "Unknown Role"
    },
    success: {
      base: "Confirmé",
      passwordReset: "Réinitialisation du mot de passe confirmée",
      passwordForgot:
        "Nous vous avons envoyé l'e-mail de confirmation. Veuillez confirmer la réinitialisation",
      update: "Mise à jour confirmée",
      delete: "Suppression confirmée",
      download: "Download réussi",
      sendToManager: "Votre devis a été envoyé",
      addressCreated: "Adresse créée avec succès",
      addressUpdated: "Adresse mise à jour avec succès",
      userCreated: "Utilisateur créé avec succès",
      userUpdated: "Utilisateur mis à jour avec succès",
      userEdited: "Utilisateur mise à jour avec succès",
      coreCatalogCreated: "Core catalog créé avec succès",
      coreCatalogUpdated: "Core catalog mis à jour avec succès",
      matchFound: "Match trouvé",
      typeDeleteSuccess: "Type éliminé avec succès",
      categoryCreated: "Catégorie créée avec succès",
      categoryDeleted: "Catégorie eliminée avec succès",
      categoryUpdated: "Catégorie mis à jour avec succès",
      customerCreated: "Client créé avec succès",
      pleaseClickNext: ", please click the \"Next\" button below to proceed with further steps.",
      customerUpdated: "Client mis à jour avec succès",
      customerDeleted: "Client eliminé avec succès",
      familyTaxCreated: "Famille fiscale créée avec succès",
      familyTaxDeleted: "Famille fiscale eliminée avec succès",
      importTaxCreated: "Taxe d'import créée avec succès",
      importTaxDeleted: "Taxe d'import eliminée avec succès",
      productCreated: "Produit créé avec succès",
      productUpdated: "Produit mis à jour avec succès",
      excelFileGenerated: "Tableur excel généré avec succès",
      defEurotaxPriceChagned: "Prix prestation eurotax mise à jour",
      shopDeleted: "Boutique effacée avec succès",
      shopCreated: "Boutique créée avec succès",
      shopEdited: "Boutique mise à jour avec succès",
      countryCreated: "Pays créé avec succès",
      countryUpdated: "Pays mis à jour avec succès",
      countryEdited: "Pays mise à jour avec succès",
    },
  },
  spa: {
    error: {
      base: "Error",
      incorrectEmailOrPassword: "Email o contraseña incorecta",
      somethingWentWrong: "Algo salió mal",
      fileDownload: "Error de descarga de archivo",
      fileSending: "Error al enviar archivo",
      onlyAnEmptyCanBeRemoved: "Solo se puede eliminar una tienda vacía",
      coreCatalogTypeCantBeRemoved: "Lo sentimos, el tipo de catálogo principal no se puede eliminar. Tiene productos asignados",
      unknownRole: "Unknown Role"
    },
    success: {
      base: "Confirmado",
      passwordReset: "Restablecimiento de contraseña confirmado",
      passwordForgot: "Le hemos enviado un email de confirmación. Por favor, confirme el reinicio",
      update: "Actualización confirmada",
      delete: "Eliminación confirmada",
      download: "Descargado con éxito",
      sendToManager: "Su cotización ha sido enviada",
      addressCreated: "Dirección creada con éxito",
      addressUpdated: "Dirección actualizada con éxito",
      userCreated: "Usuario creado con éxito",
      userUpdated: "Usuario actualizado con éxito",
      userEdited: "Usuario editada correctamente",
      coreCatalogCreated: "Catálogo principal creado con éxito",
      coreCatalogUpdated: "Catálogo principal actualizado con éxito",
      matchFound: "Coincidencia encontrada",
      typeDeleteSuccess: "Type delete success",
      categoryCreated: "Categoría creada con éxito",
      categoryUpdated: "Categoría actualizado con éxito",
      categoryDeleted: "Categoría eliminada con éxito",
      customerCreated: "Cliente creado con éxito",
      pleaseClickNext: ", please click the \"Next\" button below to proceed with further steps.",
      customerUpdated: "Cliente actualizado con éxito",
      customerDeleted: "Cliente eliminado con éxito",
      familyTaxCreated: "Familia de Impuesto creado con éxito",
      familyTaxDeleted: "Familia de Impuesto actualizado con éxito",
      importTaxCreated: "Familia de Impuesto eliminado con éxito",
      importTaxDeleted: "Familia de Impuesto actualizado con éxito",
      productCreated: "Producto creado con éxito",
      productUpdated: "Producto actualizado con éxito",
      excelFileGenerated: "Producto eliminado con éxito",
      defEurotaxPriceChagned: "El precio del servicio Eurotax predeterminado se modificó correctamente",
      shopDeleted: "Tienda eliminada con éxito",
      shopCreated: "Tienda creada con éxito",
      shopEdited: "Tienda editada correctamente",
      countryCreated: "País creado con éxito",
      countryUpdated: "País actualizado con éxito",
      countryEdited: "País editada correctamente",
    },
  },
  it: {
    error: {
      base: "Errore",
      incorrectEmailOrPassword: "E-mail o password incorretta",
      somethingWentWrong: "Si è verificato un errore",
      fileDownload: "Errore durante il download",
      fileSending: "Errore durante l'invio del file",
      onlyAnEmptyCanBeRemoved: "Si può eliminare solo un negozio vuoto",
      coreCatalogTypeCantBeRemoved: "Siamo spiacenti, il catalogo principale non può essere eliminato. Ci sono prodotti assegnati.",
      unknownRole: "Incarico non riconosciuto"
    },
    success: {
      base: "Confermato",
      passwordReset: "Password ripristinata con successo!",
      passwordForgot:
        "Ti abbiamo inviato una mail di conferma. Per favore, conferma il ripristino",
      update: "Aggiornamento riuscito",
      delete: "Eliminato con successo",
      download: "Download completato",
      sendToManager: "Il tuo preventivo è stato inviato al consulente",
      addressCreated: "Indirizzo creato con successo",
      addressUpdated: "Indirizzo aggiornato con successo",
      userCreated: "Utente creato con successo",
      userUpdated: "Utente aggiornato con successo",
      userEdited: "Utente modificato con successo",
      coreCatalogCreated: "Catalogo principale creato con successo",
      coreCatalogUpdated: "Catalogo principale aggiornato con successo",
      matchFound: "Coincidenza trovata",
      typeDeleteSuccess: "Tipo eliminato con successo",
      categoryCreated: "Categoria creta con successo",
      categoryUpdated: "Categoría aggiornato con successo",
      categoryDeleted: "Categoria eliminata con successo",
      customerCreated: "Cliente creato con successo",
      pleaseClickNext: ", please click the \"Next\" button below to proceed with further steps.",
      customerUpdated: "Cliente aggiornato con successo",
      customerDeleted: "Cliente eliminato con successo",
      familyTaxCreated: "Nucleo familiare creato con successo",
      familyTaxDeleted: "Nucleo familiare aggiornato con successo",
      importTaxCreated: "Nucleo familiare eliminato con successo",
      importTaxDeleted: "Tassa di importazione eliminata con successo",
      productCreated: "Prodotto creato con successo",
      productUpdated: "Prodotto aggiornato con successo",
      excelFileGenerated: "Prodotto eliminato con successo",
      defEurotaxPriceChagned: "Il prezzo predefinito del servizio Eurotax è stato modificato correttamente",
      shopDeleted: "Negozio eliminato con successo",
      shopCreated: "Negozio creato con successo",
      shopEdited: "Negozio modificato correttamente",
      countryCreated: "Paese creato con successo",
      countryUpdated: "Paese aggiornato con successo",
      countryEdited: "Paese modificato con successo",
    },
  },
};
