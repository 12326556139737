import { dashboard } from "@/api";

export default {
  state: {
    users: 0,
    shops: 0,
    products: 0,
    openedOrders: 0,
    errorOrders: 0,
    finishedOrders: 0
  },

  actions: {

    async getDashboardCollection({ dispatch, commit }, dayOffset = 180) {
      try {
        const { data } = await dashboard.getCollection(dayOffset)
        commit("SET_DASHBOARD_COLLECTION", data)
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },
  },

  mutations: {
    SET_DASHBOARD_COLLECTION(state, data) {
      state.users = data.users
      state.shops = data.shops
      state.products = data.products
      state.openedOrders = data.openOrders
      state.finishedOrders = data.finishedOrders
      state.errorOrders = data.errorOrders

    },
  }

}
