import { Axios } from "./config.js";

const basePath = "address"
export default class AddressService {
  async getCollection({ cid, limit = 50, page = 1, type = "" } = {}) {
    return await Axios.get(`${basePath}/${cid}/list`,
      { params: { limit, page, types: type ? `shipping_and_billing,${type}` : "shipping_and_billing" } }
    );
  }

  async get({ aid = "" } = {}) {
    return await Axios.get(`${basePath}/${aid}`);
  }

  async create({
    cid = "",
    body = {
      name: "",
      type: "",
      phone: "",
      country: "",
      area: "",
      city: "",
      address1: "",
      address2: "",
      zipcode: "",
    },
  } = {}) {
    return await Axios.post(`${basePath}/${cid}/create`, body);
  }

  async update({ aid, body = {} } = {}) {
    return await Axios.patch(`${basePath}/${aid}/update`, body);
  }
}
