export default [
    {
        path: "/shops/:id/orders",
        name: "OrdersPage",
        component: () => import("../views/Customer/Orders/OrdersPage"),
        meta: {
            auth: true,
            userRole: "ANY",
            layout: "Default"
        }
    },
    {
        path: "/shops/:id/create-order",
        name: "CreateAnOrderPage",
        component: () => import("../views/Customer/Orders/OrderPage"),
        meta: {
            auth: true,
            userRole: "ANY",
            layout: "Default"
        }
    },
    {
        path: "/shops/:id/order/:oid",
        name: "EditAnOrderPage",
        component: () => import("../views/Customer/Orders/OrderPage"),
        meta: {
            auth: true,
            userRole: "ANY",
            layout: "Default"
        }
    },
]