import { IState, OrderMutationTypes } from "./types";

export const mutations = {
  [OrderMutationTypes.SetOrderCollection](state, { items, page, limit, total }) {
    state.orders = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [OrderMutationTypes.SetOrder](state, { data }) {
    state.order = data
  },

  [OrderMutationTypes.SetOrderFile](state, { type = "", data = {} }) {
    state[type] = data
  },

  [OrderMutationTypes.SetOrderPayment](state, { data }) {
    state.payment = data
  },

  [OrderMutationTypes.SetOrderPaymentStatus](state, { data }) {
    state.payment.paymentStatus = data
  },

  [OrderMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [OrderMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  }
}