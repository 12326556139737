export default {
  en: {
		dashboard: "Dashboard",
		total: "Total",
		last7: "Last 7 days",
		last30: "Last 30 days",
		last90: "Last 90 days",
		last12: "Last 12 month",
		numberUsers: "Numer of users",
		numberShops: "Number of shops",
		productsInCatalog: "Products in users catalog",
		ordersOpened: "Orders opened",
		ordersError: "Orders with Error",
		ordersFinished: "Orders finished",
  },
  fr: {
    dashboard: "Tableau de bord",
		total: "Total",
		last7: "Derniers 7 jours",
		last30: "Derniers 30 jours",
		last90: "Derniers 90 jours",
		last12: "Derniers 12 mois",
		numberUsers: "Nº d'utilisateurs",
		numberShops: "Nº de boutiques",
		productsInCatalog: "Produits dans catalogue utilisateurs",
		ordersOpened: "Commandes ouvertes",
		ordersError: "Commandes avec erreur",
		ordersFinished: "Commandes finalisées",
  },
	spa: {
		dashboard: "Bloc de notas",
		total: "Total",
		last7: "Últimos 7 días",
		last30: "Últimos 30 días",
		last90: "Últimos 90 días",
		last12: "Últimos 12 meses",
		numberUsers: "Número de usuarios",
		numberShops: "Número de tiendas",
		productsInCatalog: "Productos en catálogo de usuarios",
		ordersOpened: "Pedidos abiertos",
		ordersError: "Pedidos do error",
		ordersFinished: "Pedidos terminados",
  },
	it: {
		dashboard: "Inizio",
		total: "Totale",
		last7: "Ultimi 7 giorni",
		last30: "Ultimi 30 giorni",
		last90: "Ultimi 90 giorni",
		last12: "Ultimi 12 mesi",
		numberUsers: "Numero di utenti",
		numberShops: "Numero di negozi",
		productsInCatalog: "Prodotti nel catalogo degli utenti",
		ordersOpened: "Ordini aperti",
		ordersError: "Ordini con errore",
		ordersFinished: "Ordini completati",
  },
};
