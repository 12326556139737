export default [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Customer/Auth/LoginForm"),
        meta: {
            userRole: "ANY",
            layout: "FullScreen"
        }
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/Customer/Auth/RegisterForm"),
        meta: {
            userRole: "ANY",
            layout: "FullScreen"
        }
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/Customer/Auth/ProfileForm"),
        meta: {
            auth: true,
            userRole: "ANY",
            layout: "FullScreen"
        }
    },
    {
        path: "/auth/reset-password/:token",
        name: "ResetPassword",
        component: () => import("../views/Customer/Auth/ResetPasswordForm"),
        meta: {
            userRole: "ANY",
            layout: "FullScreen"
        }
    },
    {
        path: "/auth/verify",
        name: "VerifyPage",
        component: () => import("../views/Customer/Auth/VerifyPage"),
        meta: {
            userRole: "ANY",
            layout: "FullScreen"
        }
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditionsPage",
        component: () => import("../views/Customer/Auth/TermsAndConditions"),
        meta: {
            userRole: "ANY",
            layout: "FullScreen"
        }
    }
]