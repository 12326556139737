export default [
  {
    path: "/home",
    name: "Home2",
    component: () => import("../views/Home"),
    meta: {
      layout: "Default",
      userRole: "ANY",
      auth: true
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
    meta: {
      layout: "Default",
      userRole: "ANY",
      auth: true
    }
  }
]