export default {
  en: {
		name : "Shop name",
		description : "Shop description",
		approvalStatus : "Approval status",
		countryOfEstablishment : "Country of estabilishment",
		countryOfStock : "Country of stock",
		productsCount : "Products",
		vatNumber: "Vat number",
		userOwnerData: {
			email: "Email",
		},

		certificateOfIncorporate : "Certificate of incorporation",
		certificate: "Certificate",
		createShop : "Create a shop",
		home : "Home",
		shops : "Shops",
		edit: "Edit",
		open: "Open",
		uploadCertifCorp : "Upload a certificate of incorporation",
		create: "Create",
		shopEdit: "Shop edit",
		user: "User",
		email: "Email",
		id: "ID",

		waiting_approval: "Waiting for approval",
		approved: "Approved",
	},
	fr: {

		userOwnerData: {
			email: "Courriel",
		},

		vatNumber: "Numéro de TVA",
		name : "Nom boutique",
		description : "Description boutique",
		productsCount : "Produits",
		countryOfEstablishment : "Pays d'établissement",
		countryOfStock : "Pays stockage",
		approvalStatus : "Etat validation",
		certificateOfIncorporate : "Certificat Reg. Stés.",
		certificate: "Certificat",
		createShop : "Créer une boutique",
		home : "Accueil",
		shops : "Boutiques",
		edit: "Editer",
		open: "Ouvert",
		uploadCertifCorp : "Téléverser certificat Regis. des Stés",
		create: "Créer",
		shopEdit: "Editer boutique",
		waiting_approval: "En attente de validation",
		approved: "Validé",
		user: "Utilisateur",
		email: "Courriel",
		id: "ID"
	},
	spa: {

		userOwnerData: {
			email: "Correo electrónico",
		},

		vatNumber: "Número de valor agregado",
		name : "Nombre de la tienda",
		description : "Descripción de tienda",
		productsCount : "Productos",
		countryOfEstablishment : "País de establecimiento",
		countryOfStock : "País de almacenamiento",
		approvalStatus : "Estado de aprobación",
		certificateOfIncorporate : "Certficado de incorporación",
		certificate: "Certificate",
		createShop : "Crear una tienda",
		home : "Página principal",
		shops : "Tiendas",
		edit: "Editar",
		open: "Abierto",
		uploadCertifCorp : "Subir un certificado de incorporación",
		create: "Crear",
		shopEdit: "Editar tienda",
		waiting_approval: "En espera de aprobación",
		approved: "Aprobado",
		user: "Usuario",
		email: "Correo electrónico",
		id: "ID"
	},
	it: {
		name : "Nome del negozio",
		description : "Descrizione del negozio",
		approvalStatus : "Stato di conferma",
		countryOfEstablishment : "Paese di stabilimento ",
		countryOfStock : "Paese di stoccaggio",
		productsCount : "Prodotti",

		userOwnerData: {
			email: "Email",
		},
		vatNumber: "Partita IVA",
		certificateOfIncorporate : "Certificato di integrazione",
		certificate: "Certificato",
		createShop : "Crea un negozio",
		home : "Home",
		shops : "Negozi",
		edit: "Modifica",
		open: "Aperto",
		uploadCertifCorp : "Carica un certificato di integrazione",
		create: "Crea",
		shopEdit: "Modifica negozio",
		user: "Utente",
		email: "Email",
		id: "ID",

		waiting_approval: "In attesa di conferma",
		approved: "Confermato",
	},
}