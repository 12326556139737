import { IState, UserMutationTypes } from "./types";

export const mutations = {
  [UserMutationTypes.SetUserCollection](state, { items, page, limit, total }) {
    state.users = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [UserMutationTypes.SetUser](state, { data }) {
    state.user = data
  },

  [UserMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [UserMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  }
}