export default [
	{
		path: "/core-catalog",
		name: "CoreCatalogPage",
		component: () => import("../views/Admin/CoreCatalog/CoreCatalogsPage"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},
	{
		path: "/core-catalog/create-product",
		name: "CreateProduct",
		component: () => import("../views/Admin/CoreCatalog/CoreCatalogPage"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},
	{
		path: "/core-catalog/edit-product/:id",
		name: "EditProduct",
		component: () => import("../views/Admin/CoreCatalog/CoreCatalogPage"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},
]
