import { Axios } from "./config.js";

export class UserService {
  static registerUser(formData) {
    return Axios.post("/auth/sign-up", formData);
  }
  static logInGetToken(formData) {
    return Axios.post("/auth/login", formData)
  }
  static refreshToken(refresh_token) {
    return Axios.post("/auth/token/refresh", {
      refresh_token
    })
  }
  static getMe() {
    return Axios.get("/user/profile");
  }
  static updateUser(id, formData) {
    return Axios.patch(`/admin/user/${id}/update`, formData);
  }
  static getUserList({page, id, firstName, lastName, email}) {
    return Axios.get("admin/user/list", { params: { page, id, firstName, lastName, email }});
  }
  static updateProfile(formData) {
    return Axios.patch("/user/update-profile", formData);
  }
  static changePassword(formData) {
    return Axios.post("/user/change-password", formData)
  }
  static resetPassword(email) {
    return Axios.post("/auth/reset-password", {
      email: email
    })
  }
  static tokenResetPassword(token, passwordData) {
    return Axios.post(`/auth/reset-password/reset/${token}`, passwordData)
  }
  static sendSupportMessage(message) {
    return Axios.post("/support", {
      message: message
    })
  }
  static verifyUser({ expires, token, id, signature}) {
    return Axios.get("/auth/verify", {
      params: {
        expires: expires,
        id: id,
        signature: signature,
        token: token,
      }
    })
  }

}
