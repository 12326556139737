export default {
  en: {
		category: "Category",
		list: "Categories List",
		name: "Category name",
		
		glass: "Glass",
		petPlastic: "Pet Plastic",
		aluminium: "Aluminium",
		steel: "Steel",
		carbonardBrick: "Carbonard Brick",
	},
	fr: {
		category: "Catégorie",
		list: "Liste des catégories",
		name: "Nom de la catégorie",

		glass: "Verre",
		petPlastic: "Plastique (PET)",
		aluminium: "Aluminium",
		steel: "Acier",
		carbonardBrick: "Brick carton",
	},
	spa: {
		category: "Categorie",
		list: "Lista de categorías",
		name: "Nombre de la categoría",

		glass: "Vidrio",
		petPlastic: "Tereftalato de polietileno",
		aluminium: "Aluminio",
		steel: "Acero",
		carbonardBrick: "ladrillo de carbonard",
	},
	it: {
		category: "Categoria",
		list: "Lista di categorie",
		name: "Nome della categoria",
		
		glass: "Vetro",
		petPlastic: "Plastica PET",
		aluminium: "Alluminio",
		steel: "Acciaio",
		carbonardBrick: "Mattone in laterizio",
	},
}