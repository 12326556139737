export default [
	{
		path: "/settings",
		name: "SettingsPage",
		component: () => import("../views/Admin/Settings-admin/SettingsPage.vue"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},
	{
		path: "/settings/countries",
		name: "CountriesPage",
		component: () => import("../views/Admin/Settings-admin/CountriesPage.vue"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		},
	},

]