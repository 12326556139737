import { Axios } from "./config.js";
import clean from "clean-deep";

const basePath = "customers"
export default class CustomersService {
  async getCollection({
    sid = "",
    params = {}
  } = {}) {
    return await Axios.get(`${basePath}/${sid}/list`, {
      params: clean(params)
    });
  }

  async search({ sid = "", search = {} } = {}) {
    return await Axios.get(`${basePath}/search/${sid}`, {
      params: { ...search }
    })
  }

  async get({ cid = "" } = {}) {
    return await Axios.get(`${basePath}/${cid}`)
  }

  async create({
    sid,
    body = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      tin: "",
      businessName: "",
      billingAddressId: null,
      shippingAddressId: null,
    },
  } = {}) {
    const {
      firstName,
      lastName,
      email,
      phone,
      tin,
      businessName,
      billingAddressId,
      shippingAddressId,
    } = body
    return await Axios.post(`${basePath}/${sid}/create`, {
      firstName,
      lastName,
      email,
      phone,
      tin,
      businessName,
      billingAddressId,
      shippingAddressId,
    });
  }

  async update({ cid, body = {} } = {}) {
    return await Axios.patch(`${basePath}/${cid}/update`, body);
  }

  async remove({ cid = "" } = {}) {
    return await Axios.delete(`${basePath}/${cid}/delete`)
  }
}
