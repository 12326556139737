import { Axios } from "./config.js";

const basePath = "admin/country-import-tax"

// aid address id
export default class ImportTaxService {
  // cid - customer id
  async getCollection({ page = 1 } = {}) {
    return await Axios.get(`${basePath}/list`, {
      params: {
        page: page
      }
    });
  }

  async get({ taxFamily = "", country = "" } = {}) {
    return await Axios.get(`country-import-tax/${taxFamily}/tax-family/${country}/country`.toLowerCase())
  }

  async create({
    body = {
      country: "",
      abvMin: 0,
      abvMax: 0,
      exciseType: "",
      exciseRate: 0,
      currency: "",
      vat: 0,
      taxFamily: "",
    },
  } = {}) {
    return await Axios.post(`${basePath}/create`, body);
  }

  async update({ id = "", body = {} } = {}) {
    return await Axios.patch(`${basePath}/${id}/update`, body)
  }

  async remove({ id = "" } = {}) {
    return await Axios.delete(`${basePath}/${id}/delete`)
  }
}
