import { IState, ShopMutationTypes } from "./types";

export const mutations = {
  [ShopMutationTypes.SetShopCollection](state, { items, page, limit, total }) {
    state.shops = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [ShopMutationTypes.SetShop](state, { data }) {
    state.shop = data
  },

  [ShopMutationTypes.SetShopCertificate](state, { data }) {
    state.certificate = data
  },

  [ShopMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [ShopMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  }
}