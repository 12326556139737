export default {
	en: {
		id: "ID",
		firstName: "First name",
		lastName: "Last name",
		email: "Email",
		role: "Role",
		accountState: "Account state",
		phone: "Phone number",

		createUser: "Create a user",
		administration: "Administration",
		password: "Password",
		confirmPassword: "Confirm Password",
	},
	fr: {
		id: "ID",
		firstName: "Prénom",
		lastName: "Nom de famille",
		email: "Courriel",
		role: "Rôle",
		accountState: "Etat compte",
		phone: "Numéro de téléphone",

		createUser: "Créer un utilisateur",
		administration: "Administration",
		password: "Mot de passe",
		confirmPassword: "Confirmer mot de passe",
	},
	spa: {
		id: "ID",
		firstName: "Nombre",
		lastName: "Apellido",
		email: "Correo electrónico",
		role: "Role",
		accountState: "Estado de la cuenta",
		phone: "Número de teléfono",

		createUser: "Crear un usuario",
		administration: "Administración",
		password: "Contraseña",
		confirmPassword: "Confirmar contraseña",
	},
	it: {
		id: "ID",
		firstName: "Nome",
		lastName: "Cognome",
		email: "Email",
		role: "Incarico",
		accountState: "Stato dell'account",
		phone: "Telefono",

		createUser: "Crea un utente",
		administration: "Administration",
		password: "Password",
		confirmPassword: "Conferma password",
	},
}