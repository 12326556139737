export default {
  en: {
		onFilterReset: "Reset filters",
  },
  fr: {
		onFilterReset: "Remise à zéro des filtres",
  },
	spa: {
		resetFilters: "Restablecer filtros",
  },
  it: {
		onFilterReset: "Reimposta filtri",
  },
};
