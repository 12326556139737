// TODO delete from actions
import { i18n } from "@/plugins/i18n";
import { product } from "@/api/";
import { ProductActionTypes, ProductMutationTypes } from "./types";

export const actions = {
  async [ProductActionTypes.GetProductCollection]({ dispatch, commit }, {
    sid = "",
    params = {},
    root = false
  } = {}) {
    commit(ProductMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await product.getCollection({ sid, params })

      commit(ProductMutationTypes.SetProductCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ProductMutationTypes.SetLoadingState)
    }
  },

  async [ProductActionTypes.SearchProductCollection]({ dispatch, commit }, { sid, search = {}, root = false } = {}) {
    commit(ProductMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await product.search({ sid, search })

      commit(ProductMutationTypes.SetProductCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ProductMutationTypes.SetLoadingState)
    }
  },

  async [ProductActionTypes.GetProduct]({ dispatch, commit }, { pid = "" } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });

    try {
      const { data } = await product.get({ pid })
      commit(ProductMutationTypes.SetProduct, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [ProductActionTypes.CreateProduct]({ dispatch, commit }, { sid = "", body = {} } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });
    try {
      const { data } = await product.create({ sid, body: { ...body } })
      commit(ProductMutationTypes.SetProduct, { data })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.productCreated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [ProductActionTypes.UpdateProduct]({ state, dispatch, commit }, { pid = "", body = {} } = {}) {
    commit("SET_LOADER_STATUS", true, { root: true });
    try {
      const $body = Object.entries(body).reduce((acc, product) => {
        const [key, value] = product;

        if (key === "reference" && state.product[key] === value) {
          return acc;
        }

        if (key === "cn8" && !body[key]) {
          acc[key] = null;
        } else {
          acc[key] = value;
        }

        return acc;
      }, {});
      const { data } = await product.update({ pid, body: { ...$body } })

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.productUpdated"),
        { root: true }
      );
      commit(ProductMutationTypes.SetProduct, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit("SET_LOADER_STATUS", false, { root: true });
    }
  },

  async [ProductActionTypes.GetProductReference]({ dispatch, commit }) {
    try {
      const { data } = await product.reference()

      commit(ProductMutationTypes.SetProductReference, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [ProductActionTypes.GetExcelExport]({ dispatch, commit }, { type = "excel", sid = "" } = {}) {
    try {
      const { data } = await product.downloadExcelExport({ sid })

      commit(ProductMutationTypes.SetProductFile, { type, data })

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.excelFileGenerated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  [ProductActionTypes.ClearState]({ commit }) {
    commit(ProductActionTypes.ClearState)
  }
}