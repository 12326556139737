export const CatalogNamespace = "catalog"

export const CatalogActionTypes = {
  GetCatalogCollection: "getCatalogCollection",
  GetCatalog: "getCatalog",
  SearchCatalogCollection: "searchCatalogCollection",
  MatchCatalog: "matchCatalog",
  GetCatalogDescriptionCollection: "getCatalogDescriptionCollection",
  CreateCatalog: "createCatalog",
  UpdateCatalog: "updateCatalog",
  RemoveCatalog: "deleteCatalogType",
  ClearState: "clearState"
}

export const CatalogMutationTypes = {
  SetCatalogCollection: "setCatalogCollection",
  SetCatalog: "setCatalog",
  SetCatalogDescriptionCollection: "SetCatalogDescriptionCollection",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  catalogs: [],
  catalog: {},
  descriptions: [],
  loading: false,
  page: 1,
  limit: 25,
  total: 0
}

export const ICatalog = {
  id: "",
  cn8: "",
  description: "",
  abvMin: 5,
  abvMax: 40,
  prodInEu: false,
  pressured: false,
  container: "moreEqual2Liters",
  colour: "white",
  variety: ["aop_pdo"],
  category: "",
  invalid: true,
  defaultTaxFamily: {},
  countriesExclusions: [],
}