import { settings } from "@/api";
import { i18n } from "@/plugins/i18n";

export default {
  state: {
    defaultEurotaxServicePrice: 0,
  },

  actions: {
    async getDefaultEurotaxServicePrice({ dispatch, commit }) {
      try {
        const { data } = await settings.getDefaultEurotaxServicePrice()
        commit("SET_SETTINGS_COLLECTION", { ...data })

      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async updateDefaultEurotaxServicePrice({ dispatch, commit }, { defaultEurotaxServicePrice }) {
      try {

        const { data } = await settings.updateDefaultEurotaxServicePrice({ defaultEurotaxServicePrice })

        commit("SET_SETTINGS_COLLECTION", { ...data })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.defEurotaxPriceChagned"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },
  },

  mutations: {
    SET_SETTINGS_COLLECTION(state, { defaultEurotaxServicePrice }) {
      state.defaultEurotaxServicePrice = defaultEurotaxServicePrice
    },

  }
}