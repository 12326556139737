export const CarrierNamespace = "carrier"

export const CarrierActionTypes = {
  GetCarrierCollection: "getCarrierCollection",
  ClearState: "clearState"
}

export const CarrierMutationTypes = {
  SetCarrierCollection: "setCarrierCollection",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  list: [],
}