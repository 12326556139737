import { Axios } from "./config.js";
import clean from "clean-deep";


const adminBasePath = "admin/country";
const publicBasePath = "country";

export default class CountryService {
  async getAllowedForAddressCollection() {
    return await Axios.get(`${publicBasePath}/allowed-for-address-list`);
  }

  async getAllowedForAddressList() {
    const { data } = await this.getAllowedForAddressCollection();

    return Object.entries(data.list).map(([key, value]) => ({ name: key, alias: value }));
  }

  async getCollection({
    params = {
      page: 1,
      limit: 25,
      id: "",
      name: "",
      countryCode: "",
      allowedForAddress: ""
    }
  } = {}) {
    return await Axios.get(`${adminBasePath}/list`, {
      params: clean(params)
    });
  }

  async get({ uid = "" } = {}) {
    return await Axios.get(`${adminBasePath}/${uid}`)
  }

  async create({ body = {} } = {}) {
    return await Axios.post(`${adminBasePath}/create`, body)
  }

  async update({ uid = "", body = {} } = {}) {
    return await Axios.patch(`${adminBasePath}/${uid}/update`, body)
  }
}
