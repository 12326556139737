import { Axios } from "./config.js";

const basePath = "admin/tax-family"

// aid address id
export default class FamilyTaxService {
  // cid - customer id
  async getCollection({ page = 1, limit = 10 } = {}) {
    return await Axios.get("tax-family/list", { params: { page, limit } });
  }

  async create({
    body = {
      name: ""
    },
  } = {}) {
    return await Axios.post(`${basePath}/create`, body);
  }

  async edit({
    id = "",
    body = {
      name: ""
    },
  } = {}) {
    return await Axios.patch(`${basePath}/${id}/update`, body);
  }

  async remove({ id }) {
    return await Axios.delete(`admin/tax-family/${id}/delete`);
  }
}
