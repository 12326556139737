export default {
  en: {
		superAdmin: "Super Admin",
		admin: "Admin",
		eurotax: "Eurotax manager",
		customer: "Customer",
		user: "User",
		coManager: "CO Manager"
  },
  fr: {
    superAdmin: "Super Admin",
		admin: "Admin",
		eurotax: "Eurotax manager",
		customer: "Client",
		user: "Utilisateur",
		coManager: "CO Manager"
  },
	spa: {
    superAdmin: "Superadminsitrador",
		admin: "Admin",
		eurotax: "Eurotax manager",
		customer: "Cliente",
		user: "Usuario",
		coManager: "cogerente"
  },
	it: {
		superAdmin: "Super Admin",
		admin: "Admin",
		eurotax: "Eurotax manager",
		customer: "Cliente",
		user: "Utente",
		coManager: "Co-Manager"
  },
};