export default {
	en: {
		edit: "Edit",
		editStatus: "Edit status",
		save: "Save",
		open: "Open",
		send: "Send",
		close: "Close",
		create: "Create",
		export: "Export",
		import: "Import",
		start: "Start",
		change: "Change",
		delete: "Delete",
		continue: "Continue",
		cancel: "Cancel",
		createNew: "Create new",
		downloadTemplate: "Download the template",
		chooseFile: "Choose a file",
		saveCreate: "Save & Create",
		saveQuit: "Save & Quit",
		addTaxRule: "Add tax rule",
		orders: "Orders",
		createOrder: "Create an order",
		products: "Products",
		createProduct: "Create a product",
		customers: "Customers",
		createCustomer: "Create a customer",
		editCustomer: "Edit customer",
		createCategory: "Create category",
		editCategory: "Edit category",
		createTaxFamily: "Create Tax Family",
		invalid: "Invalid",
		valid: "Valid",
		action: "Action",
		actions: "Actions",
		next: "Next",
		createAnotherAddress: "Create another address",
		selectNewDefault: "Select new default"
	},
	fr: {
		edit: "Editer",
		editStatus: "Editer etat",
		save: "Enregistrer",
		open: "Ouvert",
		send: "Envoyer",
		close: "Terminer",
		create: "Créer",
		export: "Exporter",
		import: "Importer",
		start: "Commencer",
		change: "Modifier",
		delete: "Effacer",
		continue: "Continuer",
		cancel: "Annuler",
		createNew: "Créer nouveau",
		downloadTemplate: "Téléch. Le modèle",
		chooseFile: "Sélectionner fichier",
		saveCreate: "Enregistrer et créer",
		saveQuit: "Enregsitrer et sortir",
		addTaxRule: "Ajouter règle fiscale",
		orders: "Commandes",
		createOrder: "Créer une commande",
		products: "Produits",
		createProduct: "Créer un produit",
		customers: "Clients",
		createCustomer: "Créer",
		editCustomer: "Editer client",
		createCategory: "Créer une catégorie",
		editCategory: "Editer catégorie",
		createTaxFamily: "Créer une famille fiscale",
		invalid: "Non valide",
		valid: "Valide",
		action: "Action",
		actions: "Actions",
		next: "Prochain",
		createAnotherAddress: "Créer une autre adresse",
		selectNewDefault: "Sélectionnez nouvelle valeur par défaut"
	},
	spa: {
		edit: "Editar",
		editStatus: "Editar estatus",
		save: "Guardar",
		open: "Abierto",
		send: "Enviar",
		close: "Cerrar",
		create: "Crear",
		export: "Exportar",
		import: "Importar",
		start: "Empezar",
		change: "Cambiar",
		delete: "Borrar",
		continue: "Continuar",
		cancel: "Cancelar",
		createNew: "Crear Nuevo",
		downloadTemplate: "Descargar la plantilla",
		chooseFile: "Elegir un archivo",
		saveCreate: "Guardar y crear",
		saveQuit: "Guardar y salir",
		addTaxRule: "Agregar una regla fiscal",
		orders: "Pedidos",
		createOrder: "Crear un pedido",
		products: "Productos",
		createProduct: "Crear un producto",
		customers: "Clientes",
		createCustomer: "Crear un cliente",
		editCustomer: "Editar cliente",
		createCategory: "Crear una categoría",
		editCategory: "Editar catégorie",
		createTaxFamily: "Crear la familia fiscal",
		invalid: "Inválido",
		valid: "Válido",
		action: "Acción",
		actions: "Acciones",
		next: "Siguiente",
		createAnotherAddress: "Crear otra dirección",
		selectNewDefault: "Seleccionar nuevo predeterminado"
	},
	it: {
		edit: "Modifica",
		editStatus: "Modifica status",
		save: "Salva",
		open: "Aperto",
		send: "Invia",
		close: "Chiudi",
		create: "Crea",
		export: "Esporta",
		import: "Importa",
		start: "Inizia",
		change: "Modifica",
		delete: "Elimina",
		continue: "Continua",
		cancel: "Annulla",
		createNew: "Crea Nuovo",
		downloadTemplate: "Scarica il modello",
		chooseFile: "Seleziona un file",
		saveCreate: "Salva e crea",
		saveQuit: "Salva ed esci",
		addTaxRule: "Aggiungi norme fiscali",
		orders: "Ordini",
		createOrder: "Crea un ordine",
		products: "Prodotti",
		createProduct: "Crea un prodotto",
		customers: "Clienti",
		createCustomer: "Crea un cliente",
		editCustomer: "Modifica cliente",
		createCategory: "Crea una categoria",
		editCategory: "Modifica catégorie",
		createTaxFamily: "Crea nucleo familiare",
		invalid: "Non è valido",
		valid: "Valido",
		action: "Operazione",
		actions: "Operazioni",
		next: "Prossimo",
		createAnotherAddress: "Crea un altro indirizzo",
		selectNewDefault: "Seleziona nuovo predefinito"
	},
}