export const CountryNamespace = "country"

export const CountryActionTypes = {
  GetCountryCollection: "getCountryCollection",
  GetCountryAllowedForAddressCollection: "getAllowedForAddressCollection",
  GetCountry: "getCountry",
  CreateCountry: "CreateCountry",
  UpdateCountry: "updateCountry",
  ClearState: "clearState"
}

export const CountryMutationTypes = {
  SetCountryCollection: "setCountryCollection",
  SetCountryAllowedForAddressCollection: "setAllowedForAddressCollection",
  SetCountry: "setCountry",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  countries: [],
  country: {},
  page: 1,
  limit: 25,
  total: 0,
  allowedForAddressItems: [],
}

export const ICountry = {
  id: "",
  name: "",
  countryCode: "",
  allowedForAddress: false,
  createdAt: "",
  updateAt: ""
}