<template>
  <component :is="layout">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </component>
</template>

<script>
const defaultLayout = "Default";

export default {
  name: "App",

  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "Layout";
    },
  },
};
</script>
