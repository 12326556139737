export default {
	en: {
		Austria: "Austria",
		Australia: "Australia",
		Belgium: "Belgium",
		Bulgaria: "Bulgaria",
		Switzerland: "Switzerland",
		Cyprus: "Cyprus",
		Czechia: "Czechia",
		Germany: "Germany",
		Denmark: "Denmark",
		Estonia: "Estonia",
		Spain: "Spain",
		Finland: "Finland",
		France: "France",
		Greece: "Greece",
		Croatia: "Croatia",
		Hungary: "Hungary",
		Ireland: "Ireland",
		Italy: "Italy",
		Lithuania: "Lithuania",
		Luxembourg: "Luxembourg",
		Latvia: "Latvia",
		Malta: "Malta",
		Netherlands: "Netherlands",
		Norway: "Norway",
		Poland: "Poland",
		Portugal: "Portugal",
		Romania: "Romania",
		Sweden: "Sweden",
		Slovenia: "Slovenia",
		Slovakia: "Slovakia",
		"United Kingdom": "United Kingdom",
		"United States of America": "United States of America",
	},
	fr: {
		Austria: "Autriche",
		Australia: "Australie",
		Belgium: "Belgique",
		Bulgaria: "Bulgarie",
		Switzerland: "Suisse",
		Cyprus: "Chypre",
		Czechia: "Tchéquie",
		Germany: "Allemagne",
		Denmark: "Danemark",
		Estonia: "Estonie",
		Spain: "Espagne",
		Finland: "Finlande",
		France: "France",
		Greece: "Grèce",
		Croatia: "Croatie",
		Hungary: "Hongrie",
		Ireland: "Irlande",
		Italy: "Italie",
		Lithuania: "Lituanie",
		Luxembourg: "Luxembourg",
		Latvia: "Lettonie",
		Malta: "Malte",
		Netherlands: "Néerlandais",
		Norway: "Norvège",
		Poland: "Pologne",
		Portugal: "Portugal",
		Romania: "Roumanie",
		Sweden: "Suède",
		Slovenia: "Slovénie",
		Slovakia: "Slovaquie",
		"United Kingdom": "Royaume",
		"United States of America": "États-Unis d'Amérique",
	},
	spa: {
		Austria: "Austria",
		Australia: "Australia",
		Belgium: "Bélgica",
		Bulgaria: "Bulgaria",
		Switzerland: "Suiza",
		Cyprus: "Chipre",
		Czechia: "República Checa",
		Germany: "Alemania",
		Denmark: "Dinamarca",
		Estonia: "Estonia",
		Spain: "Espa",
		Finland: "Finlandia",
		France: "Francia",
		Greece: "Grecia",
		Croatia: "Croacia",
		Hungary: "Hungría",
		Ireland: "Irlanda",
		Italy: "Italia",
		Lithuania: "Lituania",
		Luxembourg: "Luxemburgo",
		Latvia: "Letonia",
		Malta: "Malta",
		Netherlands: "Neerlandés",
		Norway: "Noruega",
		Poland: "Polonia",
		Portugal: "Portugal",
		Romania: "Rumania",
		Sweden: "Suecia",
		Slovenia: "Eslovenia",
		Slovakia: "Eslovaquia",
		"United Kingdom": "Reino Unido",
		"United States of America": "Estados Unidos de América",
	},
	it: {
		Austria: "Austria",
		Australia: "Australia",
		Belgium: "Belgio",
		Bulgaria: "Bulgaria",
		Switzerland: "Svizzera",
		Cyprus: "Cipro",
		Czechia: "Cechia",
		Germany: "Germania",
		Denmark: "Danimarca",
		Estonia: "Estonia",
		Spain: "Spagna",
		Finland: "Finlandia",
		France: "France",
		Greece: "Grecia",
		Croatia: "Croazia",
		Hungary: "Ungheria",
		Ireland: "Irlanda",
		Italy: "Italia",
		Lithuania: "Lituania",
		Luxembourg: "Lussemburgo",
		Latvia: "Lettonia",
		Malta: "Malta",
		Netherlands: "Olanda",
		Norway: "Norvegia",
		Poland: "Polonia",
		Portugal: "Portogallo",
		Romania: "Romania",
		Sweden: "Svezia",
		Slovenia: "Slovenia",
		Slovakia: "Slovacchia",
		"United Kingdom": "Regno Unito",
		"United States of America": "Stati Uniti d'America",
	},
};
