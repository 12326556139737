import { i18n } from "@/plugins/i18n";
import { country } from "@/api/";
import { CountryActionTypes, CountryMutationTypes } from "./types";

export const actions = {
  async [CountryActionTypes.GetCountryAllowedForAddressCollection]({ dispatch, commit }) {
    await actions.loadAndStoreCountryAllowedForAddressCollection({dispatch, commit});
  },

  async loadAndStoreCountryAllowedForAddressCollection({ dispatch, commit }) {
    try {
      const list = await country.getAllowedForAddressList();

      commit(CountryMutationTypes.SetCountryAllowedForAddressCollection, { list });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CountryActionTypes.GetCountryCollection]({ dispatch, commit }, {
    params = {},
    root = false
  } = {}) {
    commit(CountryMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await country.getCollection({ params });

      commit(CountryMutationTypes.SetCountryCollection, { ...data });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CountryMutationTypes.SetLoadingState);
    }
  },

  async [CountryActionTypes.GetCountry]({ dispatch, commit }, { uid = "", root = false } = {}) {
    commit(CountryMutationTypes.SetLoadingState, { root });
    try {
      const { data } = await country.get({ uid });
      commit(CountryMutationTypes.SetCountry, { data });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CountryMutationTypes.SetLoadingState);
    }
  },

  async [CountryActionTypes.CreateCountry]({ dispatch, commit }, { body = {}, root = false } = {}) {
    commit(CountryMutationTypes.SetLoadingState, { root });

    try {
      const { data } = await country.create({ body });

      commit(CountryMutationTypes.SetCountry, { data });

      await actions.loadAndStoreCountryAllowedForAddressCollection({dispatch, commit});
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CountryMutationTypes.SetLoadingState);
    }
  },

  async [CountryActionTypes.UpdateCountry]({ dispatch, commit }, { uid = "", body = {} } = {}) {
    try {
      const { data } = await country.update({ uid, body });

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.countryUpdated"),
        { root: true }
      );
      commit(CountryMutationTypes.SetCountry, { data });

      await actions.loadAndStoreCountryAllowedForAddressCollection({dispatch, commit});
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  [CountryActionTypes.ClearState]({ commit }) {
    commit(CountryActionTypes.ClearState);
  }
}