import { Axios } from "./config.js";
import clean from "clean-deep";

export default class ProductService {
  async getCollection({
    sid,
    params = {}
  } = {}) {
    return await Axios.get(`products/${sid}/list`, {
      params: clean(params)
    });
  }

  async get({ pid }) {
    return await Axios.get(`products/${pid}`)
  }

  async search({ sid = "", search = {} } = {}) {
    return await Axios.get(`products/search/${sid}`, {
      params: { ...search }
    })
  }

  async create({
    sid = "",
    body = {
      reference: "",
      productName: "",
      productDescription: "",
      productListShortDescription: "",
      abv: 0,
      typeOfMaterial: "glass",
      litersPerUnit: 1000,
      weightOfTheUnit: 90,
      price: 0,
      eanCode: "",
      coreCatalogId: 0,
      state: false
    },
  } = {}) {
    return await Axios.post(`products/${sid}/create`, body);
  }

  async update({
    pid = "",
    body = {
      reference: "",
      productName: "",
      productDescription: "",
      productListShortDescription: "",
      abv: 0,
      typeOfMaterial: "glass",
      litersPerUnit: 1000,
      weightOfTheUnit: 90,
      price: 0,
      eanCode: "",
      coreCatalogId: 0
    },
  } = {}) {
    return await Axios.patch(`products/${pid}/update`, body);
  }

  async reference() {
    return await Axios.get("helper/generate-reference")
  }

  async downloadExcelExport({ sid = "" }) {
    return await Axios.post(`products/${sid}/excel-export`, {}, {
      responseType: "blob"
    })
  }
}
