import { Axios } from "./config.js";
import clean from "clean-deep";

const basePath = "shops"
export default class ShopService {
  async getCollection({ admin = false, params = {
    page: 1,
    limit: 25,
    id: "",
  } } = {}) {
    if (admin) {
      return await Axios.get(`admin/${basePath}/list`, {
        params: clean(params)
      })
    }
    return await Axios.get(`${basePath}/list`);
  }

  async get({ sid = "" } = {}) {
    return await Axios.get(`${basePath}/${sid}`)
  }

  async create({
    body = {
      name: "string",
      description: "string",
      countryOfEstablishment: "",
      countryOfStock: ""
    }
  } = {}) {
    return await Axios.post(`${basePath}/create`, body);
  }

  async update({
    admin = false,
    sid = "",
    body = {}
  } = {}) {
    if (admin) {
      return await Axios.patch(`admin/${basePath}/${sid}/update`, body);
    }
    return await Axios.patch(`${basePath}/${sid}/update`, body);
  }

  async remove({
    sid = "",
  } = {}) {
    return await Axios.delete(`${basePath}/${sid}/delete`);
  }

  async downloadCertificate({ sid = "" } = {}) {
    return await Axios.get(`${basePath}/${sid}/download-certificate-of-incorporate`, { responseType: "blob" });
  }

  async uploadCertificate({ sid = "", body = {} } = {}) {
    return await Axios.post(`${basePath}/${sid}/upload-certificate-of-incorporate`, body);
  }

}
