export default {
    en: {
        home: "Home",
        users: "Users",
        dashboard: "Dashboard",
        shops: "Shops",
        administration: "Administration",
        orders: "Orders",
        coreCatalog: "Core catalog",
        createProduct: "Create product",
        edaVatCat: "EDA & VAT & Categories",
        settings: "Settings",
        support: "Support",
        profile: "Profile",
        logout: "Logout",
        supportMes: "Support message",
        editCountries: "Edit countries",
    },
    fr: {
        home: "Accueil",
        users: "Utilisateurs",
        dashboard: "Tableau de bord",
        shops: "Boutiques",
        administration: "Administration",
        orders: "Commandes",
        coreCatalog: "Catalogue central",
        createProduct: "Créer un produit",
        edaVatCat: "Catégories TVA et accises",
        settings: "Paramètres",
        support: "Support",
        profile: "Profil",
        logout: "Déconnexion",
        supportMes: "Message de support",
        editCountries: "Modifier les pays",
    },
    spa: {
        home: "Página principal",
        users: "Usuarios",
        dashboard: "Bloc de notas",
        shops: "Tiendas",
        administration: "Administración",
        orders: "Pedidos",
        coreCatalog: "Catálogo prinicpal",
        createProduct: "Crear un producto",
        edaVatCat: "Categorías IIEE e IVA",
        settings: "Ajustes",
        support: "Soporte",
        profile: "Perfil",
        logout: "Cerrar sesión",
        supportMes: "Mensaje de soporte",
        editCountries: "Editar países",
    },
    it: {
        home: "Home",
        users: "Utenti",
        dashboard: "Inizio",
        shops: "Negozi",
        administration: "Amministrazione",
        orders: "Ordini",
        coreCatalog: "Catalogo principale",
        createProduct: "Crea un prodotto",
        edaVatCat: "Categorie imposte e IVA",
        settings: "Impostazioni",
        support: "Supporto",
        profile: "Profilo",
        logout: "Esci",
        supportMes: "Messaggio di supporto",
        editCountries: "Modifica paesi",
    },
}