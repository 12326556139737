<template>
  <v-app>
    <v-container
      fluid
      :style="{
        minHeight: '100vh',
      }"
    >
      <Header />
      <Loader v-if="$store.getters.getLoadingStatus"></Loader>
      <Snackbar></Snackbar>
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    Header: () => import("../components/Header.vue"),
    Loader: () => import("../components-ui/Loader/Loader"),
    Snackbar: () => import("../components-ui/Snackbar/Snackbar"),
  },
};
</script>