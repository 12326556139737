export default {
	en: {
		id: "ID",
		name: "Address name",
		type: "Type",
		phone: "Phone number",
		country: "Country",
		area: "State/province/area",
		city: "City",
		address: "Address",
		address1: "Address 1",
		address2: "Address 2",
		zipcode: "Zip code",
		createdAt: "Creation date",

		shippingAddress: "Shipping address",
		billingAddress: "Billing address",
	},
	fr: {
		id: "ID",
		name: "Adresse",
		type: "Type",
		phone: "Numéro de téléphone",
		country: "Pays",
		area: "Etat/prov./région",
		city: "Ville",
		address: "Adresse",
		address1: "Adresse 1",
		address2: "Adresse 2",
		zipcode: "Code postal",
		createdAt: "Date création",

		billingAddress: "Adresse de facturation",
		shippingAddress: "Adresse de livraison",
	},
	spa: {
		id: "ID",
		name: "Dirección",
		type: "Tipo",
		phone: "Numero de teléfono",
		country: "País",
		area: "Estado/provincia/Communidad",
		city: "Ciudad",
		address: "Dirección",
		address1: "Dirección 1",
		address2: "Dirección 2",
		zipcode: "Código postal",
		createdAt: "Fecha de creación",

		billingAddress: "Dirección de facturación",
		shippingAddress: "Direccion de envío",
	},
	it: {
		id: "ID",
		name: "Nome dell'indirizzo",
		type: "Tipo",
		phone: "Numero di telefono",
		country: "Paese",
		area: "Stato/provincia/regione",
		city: "Città",
		address: "Indirizzo",
		address1: "Indirizzo 1",
		address2: "Indirizzo 2",
		zipcode: "Codice postale",
		createdAt: "Data di creazione",

		shippingAddress: "Indirizzo di spedizione",
		billingAddress: "Indirizzo di fatturazione",
	},
}