import router from "@/router/index.js";
const ROLES = {
  ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_EUROTAX: "ROLE_EUROTAX",
  ROLE_CUSTOMER: "ROLE_EUROTAX",
  ROLE_USER: "ROLE_USER",
  ROLE_CO_MANAGER: "ROLE_CO_MANAGER",
}

export default {
  state: {
    authenticated: false,
    accessToken: localStorage.getItem("EUROTAX_TOKEN") || null,
    refreshToken: null,
    userProfile: {
      full_name: "",
      is_superuser: localStorage.getItem("EUROTAX_IS_SUPER_ADMIN") === "true" || null,
      roles: {
        ROLE_SUPER_ADMIN: false,
        ROLE_ADMIN: false,
        ROLE_EUROTAX: false,
        ROLE_CUSTOMER: false,
        ROLE_USER: false,
        ROLE_CO_MANAGER: false,
      }
    },
  },
  getters: {
    getAuthenticationStatus(state) {
      return state.authenticated;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    isSuperUser(state) {
      return state.userProfile?.is_superuser
    }
  },
  mutations: {
    SET_AUTHENTICATION_STATUS(state, payload) {
      state.authenticated = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload?.token;
      state.refreshToken = payload?.refresh_token;
      localStorage.setItem("EUROTAX_TOKEN", state.accessToken);
      payload ? (state.authenticated = true) : (state.authenticated = false);
    },
    SET_USER_PROFILE(state, payload) {
      localStorage.setItem("EUROTAX_USER_ROLE", payload.roles);
      state.userProfile = payload;
      const roles = payload.roles.reduce((result, current) => {
        current === ROLES[current] ? result[current] = true : false;
        return result
      }, {
        ROLE_SUPER_ADMIN: false,
        ROLE_ADMIN: false,
        ROLE_EUROTAX: false,
        ROLE_CUSTOMER: false,
        ROLE_USER: false,
        ROLE_CO_MANAGER: false,
      });
      state.userProfile.roles = roles
      
      
    },
    CLEAR_USER_PROFILE(state) {
      state.userProfile = null;
    },
    LOGOUT(state) {
      localStorage.removeItem("EUROTAX_TOKEN");
      localStorage.removeItem("EUROTAX_IS_SUPER_ADMIN");
      state.refreshToken = null
      router.go(0);
    },
  },
};
