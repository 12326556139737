import { i18n } from "@/plugins/i18n";
import { customer } from "@/api/";
import { CustomerActionTypes, CustomerMutationTypes } from "./types";

export const actions = {
  async [CustomerActionTypes.GetCustomerCollection]({ dispatch, commit }, {
    sid = "",
    params = {},
    root = false
  } = {}) {
    commit(CustomerMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await customer.getCollection({ sid, params });

      commit(CustomerMutationTypes.SetCustomerCollection, { ...data });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
    finally {
      commit(CustomerMutationTypes.SetLoadingState, { root })
    }
  },

  async [CustomerActionTypes.SearchCustomerCollection]({ dispatch, commit }, { sid = "", search = {}, root = false } = {}) {
    commit(CustomerMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await customer.search({ sid, search });

      commit(CustomerMutationTypes.SetCustomerCollection, { ...data });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
    finally {
      commit(CustomerMutationTypes.SetLoadingState, { root })
    }
  },

  async [CustomerActionTypes.GetCustomer]({ dispatch, commit }, { sid = "", cid = "" } = {}) {
    try {
      const { data } = await customer.get({ sid, cid });

      commit(CustomerMutationTypes.SetCustomer, { data });
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CustomerActionTypes.CreateCustomer]({ dispatch, commit }, { sid = "", body = {} } = {}) {
    try {
      const { data } = await customer.create({ sid, body });

      commit(CustomerMutationTypes.SetCustomer, { data });
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.customerCreated") + i18n.t("snackbar.success.pleaseClickNext"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CustomerActionTypes.UpdateCustomer]({ dispatch, commit }, { cid = "", body = {} } = {}) {
    try {
      await customer.update({ cid, body });
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.customerUpdated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CustomerActionTypes.RemoveCustomer]({ dispatch, commit }, { cid = "" } = {}) {
    try {
      await customer.remove({ cid });
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.customerDeleted"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  [CustomerActionTypes.ClearState]({ commit }) {
    commit(CustomerMutationTypes.ClearState)
  },

  clearCustomerState({ commit }) {
    commit(CustomerMutationTypes.SetCustomer, { data: {} });
  },

  setCustomerCreation({commit}, creation) {
    commit(CustomerMutationTypes.SetCreationState, creation)
  }
}