export default [
    {
        path: "/users",
        name: "UsersPage",
        component: () => import("../views/Admin/Users/UsersPage"),
        meta: {
            auth: true,
            userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
            layout: "Default"
        }
    },
]