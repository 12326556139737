export default {
  en: {
		languageName: "Language",
	},
	fr: {
		languageName: "Langue"
	},
	spa: {
		languageName: "Idioma",
	},
  it: {
		languageName: "Lingua",
	},
}