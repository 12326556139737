import Vue from "vue";
import Vuex from "vuex";
import { product } from "./modules/product"
import { carrier } from "./modules/carrier"
import { customer } from "./modules/customer"
import { order } from "./modules/order"
import { user } from "./modules/user"
import { catalog } from "./modules/catalog"
import { shop } from "./modules/shop"
import loaderModule from "./modules/loader";
import authModule from "./modules/auth";
import snackbarModule from "./modules/snackbarModule";
import address from "./modules/address"
import importTax from "./modules/importTax"
import familyTax from "./modules/familyTax"
import category from "./modules/category"
import { country  } from "./modules/country"
import dashboard from "./modules/dashboard"
import settings from "./modules/settings"


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    loaderModule,
    product,
    customer,
    carrier,
    order,
    user,
    catalog,
    shop,
    authModule: {
      namespaced: true,
      ...authModule,
    },
    snackbarModule: {
      namespaced: true,
      ...snackbarModule,
    },
    address: {
      namespaced: true,
      ...address
    },
    importTax: {
      namespaced: true,
      ...importTax
    },
    familyTax: {
      namespaced: true,
      ...familyTax
    },
    category: {
      namespaced: true,
      ...category
    },
    country: {
      namespaced: true,
      ...country
    },
    dashboard: {
      namespaced: true,
      ...dashboard
    },
    settings: {
      namespaced: true,
      ...settings
    }
  },
});
