export default {
  en: {
		countriesTaxValues: "Countries import Tax values",
		countryShort: "Country short",
		country: "Country",
		abv: "ABV(%)",
		abvMin: "ABV (%) Min",
		abvMax: "ABV (%) Max",
		exciseType: "Excise type",
		exciseRate: "Excise rate",
		currency: "Currency",
		vat: "VAT (%)",
	},
	fr: {
		countriesTaxValues: "Importation taxe par pays",
		countryShort: "Pays (court)",
		country: "Pays",
		abv: "Degré(%)",
		abvMin: "Degré (%) Min",
		abvMax: "Degré (%) Max",
		exciseType: "Type accises",
		exciseRate: "Taux accises",
		currency: "Devise",
		vat: "TVA (%)",
	},
	spa: {
		countriesTaxValues: "Impuesto de importacion por pais",
		countryShort: "País (corto)",
		country: "País",
		abv: "Grados(%)",
		abvMin: "Grados(%) Min",
		abvMax: "Grados(%) Max",
		exciseType: "Tipo de IIEE de bebidas alcohólicas",
		exciseRate: "Tasa sobre el impuesto especial sobre bebidas alcohólicas",
		currency: "Divida",
		vat: "IVA(%)",
	},
	it: {
		countriesTaxValues: "Tariffe doganali per paese",
		countryShort: "Paese (abbreviato)",
		country: "Paese",
		abv: "Alc/vol.",
		abvMin: "Alc/vol. (%) Min",
		abvMax: "Alc/vol. (%) Max",
		exciseType: "Tipo di accisa",
		exciseRate: "Tassa accise",
		currency: "Valuta",
		vat: "IVA (%)",
	},
}