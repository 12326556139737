import { familyTax } from "@/api";
import { i18n } from "@/plugins/i18n";

export const familyTaxModel = {
  id: "",
  name: ""
}

export const types = {
  FamilyTaxNamespace: "familyTax",
  FamilyTaxActionTypes: {
    GetFamilyTaxCollection: "getFamilyTaxCollection",
    CreateFamilyTax: "createFamilyTax",
    EditFamilyTax: "editFamilyTax",
    RemoveFamilyTax: "removeFamilyTax"
  },
  FamilyTaxMutationTypes: {
    SetTaxCollection: "SET_TAX_COLLECTION"
  }
}

export default {
  state: {
    items: [],
    item: {},
    page: 1,
    limit: 10,
    total: 0
  },

  actions: {
    async [types.FamilyTaxActionTypes.GetFamilyTaxCollection]({ dispatch, commit }, { page = 1, limit = 10 } = {}) {
      try {
        const { data } = await familyTax.getCollection({ page: page, limit: limit })
        commit(types.FamilyTaxMutationTypes.SetTaxCollection, { data })
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.FamilyTaxActionTypes.CreateFamilyTax]({ dispatch, commit }, { body } = {}) {
      try {
        await familyTax.create({ body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.familyTaxCreated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.FamilyTaxActionTypes.EditFamilyTax]({ dispatch, commit }, { id = "", body = {} } = {}) {
      try {
        await familyTax.edit({ id, body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          "Tax-family successfully updated",
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.FamilyTaxActionTypes.RemoveFamilyTax]({ dispatch, commit }, { id } = {}) {
      try {
        await familyTax.remove({ id })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.familyTaxDeleted"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    }
  },

  mutations: {
    [types.FamilyTaxMutationTypes.SetTaxCollection](state, { data }) {
      Object.assign(state, data)
    },
  }
}