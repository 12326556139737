import validations from "./validations";
import authPages from "./authPages";
import snackbar from "./snackbar";
import language from "./language";
import shops from "./shops";
import dashboard from "./dashboard";
import users from "./users";
import breadcrumbs from "./breadcrumbs";
import btns from "./btns";
import administration from "./administration";
import orders from "./orders";
import coreCatalog from "./coreCatalog";
import categories from "./categories";
import settings from "./settings";
import products from "./products";
import customers from "./customers";
import countries from "./countries";
import country from "./country";
import roles from "./roles";
import filters from "./filters";
import address from "./address";
import countryImportTax from "./countryImportTax";
import taxFamily from "./taxFamily";
import tips from "./tips";

const en = {
  validations: {
    ...validations.en,
  },
  authPages: {
    ...authPages.en,
  },
  snackbar: {
    ...snackbar.en,
  },
  language: {
    ...language.en,
  },
  shops: {
    ...shops.en,
  },
  dashboard: {
    ...dashboard.en,
  },
  users: {
    ...users.en,
  },
  breadcrumbs: {
    ...breadcrumbs.en,
  },
  btns: {
    ...btns.en,
  },
  administration: {
    ...administration.en,
  },
  orders: {
    ...orders.en,
  },
  coreCatalog: {
    ...coreCatalog.en,
  },
  categories: {
    ...categories.en,
  },
  settings: {
    ...settings.en,
  },
  products: {
    ...products.en,
  },
  customers: {
    ...customers.en,
  },
  countries: {
    ...countries.en,
  },
  country: {
    ...country.en,
  },
  roles: {
    ...roles.en,
  },
  filters: {
    ...filters.en,
  },
  address: {
    ...address.en,
  },
  countryImportTax: {
    ...countryImportTax.en,
  },
  taxFamily: {
    ...taxFamily.en,
  },
  tips: {
    ...tips.en,
  },
};

const fr = {
  validations: {
    ...validations.fr,
  },
  authPages: {
    ...authPages.fr,
  },
  snackbar: {
    ...snackbar.fr,
  },
  language: {
    ...language.fr,
  },
  shops: {
    ...shops.fr,
  },
  dashboard: {
    ...dashboard.fr,
  },
  users: {
    ...users.fr,
  },
  breadcrumbs: {
    ...breadcrumbs.fr,
  },
  btns: {
    ...btns.fr,
  },
  administration: {
    ...administration.fr,
  },
  orders: {
    ...orders.fr,
  },
  coreCatalog: {
    ...coreCatalog.fr,
  },
  categories: {
    ...categories.fr,
  },
  settings: {
    ...settings.fr,
  },
  products: {
    ...products.fr,
  },
  customers: {
    ...customers.fr,
  },
  countries: {
    ...countries.fr,
  },
  country: {
    ...country.fr,
  },
  roles: {
    ...roles.fr,
  },
  filters: {
    ...filters.fr,
  },
  address: {
    ...address.fr,
  },
  countryImportTax: {
    ...countryImportTax.fr,
  },
  taxFamily: {
    ...taxFamily.fr,
  },
  tips: {
    ...tips.fr,
  },
};

const spa = {
  validations: {
    ...validations.spa,
  },
  authPages: {
    ...authPages.spa,
  },
  snackbar: {
    ...snackbar.spa,
  },
  language: {
    ...language.spa,
  },
  shops: {
    ...shops.spa,
  },
  dashboard: {
    ...dashboard.spa,
  },
  users: {
    ...users.spa,
  },
  breadcrumbs: {
    ...breadcrumbs.spa,
  },
  btns: {
    ...btns.spa,
  },
  administration: {
    ...administration.spa,
  },
  orders: {
    ...orders.spa,
  },
  coreCatalog: {
    ...coreCatalog.spa,
  },
  categories: {
    ...categories.spa,
  },
  settings: {
    ...settings.spa,
  },
  products: {
    ...products.spa,
  },
  customers: {
    ...customers.spa,
  },
  countries: {
    ...countries.spa,
  },
  country: {
    ...country.spa,
  },
  roles: {
    ...roles.spa,
  },
  filters: {
    ...filters.spa,
  },
  address: {
    ...address.spa,
  },
  countryImportTax: {
    ...countryImportTax.spa,
  },
  taxFamily: {
    ...taxFamily.spa,
  },
  tips: {
    ...tips.spa,
  },
};

const it = {
  validations: {
    ...validations.it,
  },
  authPages: {
    ...authPages.it,
  },
  snackbar: {
    ...snackbar.it,
  },
  language: {
    ...language.it,
  },
  shops: {
    ...shops.it,
  },
  dashboard: {
    ...dashboard.it,
  },
  users: {
    ...users.it,
  },
  breadcrumbs: {
    ...breadcrumbs.it,
  },
  btns: {
    ...btns.it,
  },
  administration: {
    ...administration.it,
  },
  orders: {
    ...orders.it,
  },
  coreCatalog: {
    ...coreCatalog.it,
  },
  categories: {
    ...categories.it,
  },
  settings: {
    ...settings.it,
  },
  products: {
    ...products.it,
  },
  customers: {
    ...customers.it,
  },
  countries: {
    ...countries.it,
  },
  country: {
    ...country.it,
  },
  roles: {
    ...roles.it,
  },
  filters: {
    ...filters.it,
  },
  address: {
    ...address.it,
  },
  countryImportTax: {
    ...countryImportTax.it,
  },
  taxFamily: {
    ...taxFamily.it,
  },
  tips: {
    ...tips.it,
  },
};

export default { en, fr, spa, it };
