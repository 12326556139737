import { Axios } from "./config.js";

const basePath = "user"

export default class ProfileService {
  async getProfile() {
    return await Axios.get(`${basePath}/profile`)
  }

  async updateProfile({ body = {} } = {}) {
    return await Axios.patch(`${basePath}/update-profile`, body)
  }

  async changePassword({ body = {} } = {}) {
    return await Axios.post(`${basePath}/change-password`, body)
  }

  async sendSupportMessage({ body = { message: "" } } = {}) {
    return await Axios.post("/support", body)
  }
}


