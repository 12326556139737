export default [
{
		path: "/shops/:id/products",
		name: "ProductsPage",
		component: () => import("../views/Customer/Products/ProductsPage"),
		meta: {
			auth: true,
			userRole: "ANY",
			layout: "Default"
		}
	},
	{
		path: "/products-import",
		name: "ProductsImportPage",
		component: () => import("../views/Customer/Products/ProductsImportPage"),
		meta: {
			auth: true,
			userRole: "ANY",
			layout: "Default"
		}
	},
	{
		path: "/shops/:id/create-product",
		name: "CreateProductPage",
		component: () => import("../views/Customer/Products/ProductPage"),
		meta: {
			auth: true,
			userRole: "ANY",
			layout: "Default"
		}
	},
	{
		path: "/shops/:id/edit-product/:pid",
		name: "EditProductPage",
		component: () => import("../views/Customer/Products/ProductPage"),
		meta: {
			auth: true,
			userRole: "ANY",
			layout: "Default"
		}
	},
]