export const ShopNamespace = "shop"

export const ShopActionTypes = {
  GetShopCollection: "getShopCollection",
  GetShop: "getShop",
  GetShopCertificate: "getShopCertificate",
  UploadShopCertificate: "uploadShopCertificate",
  CreateShop: "createShop",
  UpdateShop: "updateShop",
  RemoveShop: "removeShop",
  ClearState: "clearState"
}

export const ShopMutationTypes = {
  SetShopCollection: "setShopCollection",
  SetShop: "setShop",
  SetShopCertificate: "setShopCertificate",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  shops: [],
  shop: {},
  certificate: "",
  page: 1,
  limit: 25,
  total: 0,
}

export const IShop = {
  id: "",
  name: "",
  address1: "",
  address2: "",
  zipcode: "",
  email: "",
  phone: "",
  area: "",
  city: "",
  vatNumber: "",
  description: "",
  countryOfEstablishment: "",
  approvalStatus: "waiting_approval",
  countryOfStock: ""
}