export default {
  en: {
		taxFamily: "Tax Family",
		list: "Tax Family List",
		name: "Tax name",
	},
	fr: {
		taxFamily: "Famille fiscale",
		list: "Liste des familles fiscales",
		name: "Nom de la taxe",
	},
	spa: {
		taxFamily: "Familia fiscal",
		list: "Lista de familias fiscales",
		name: "Nombre fiscal",
	},
	it: {
		taxFamily: "Nucleo familiare",
		list: "Lista di nucleo familiare",
		name: "Nome fiscale",
	},
}