import Vue from "vue"
import VueRouter from "vue-router"
import Home from "./home"
import AuthenticationRoutes from "./auth"
import ShopsRoutes from "./shops"
import OrdersRoutes from "./orders"
import ProductsRoutes from "./products"
import CustomersRoutes from "./customers"
import DashboardRoutes from "./dashboard"
import CoreCatalogRoutes from "./coreCatalog"
import UsersRoutes from "./users"
import AdministrationRoutes from "./administration"
import OrdersAdminRoutes from "./orders-admin"
import SettingsRoutes from "./settings"
import EdaVatRoutes from "./eda-vat"
import PaymentRoutes from "./payment";
import {isUser, isPrevelegedUser} from "@/helpers/constants";
Vue.use(VueRouter)


const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes:[
    ...Home,
    ...AuthenticationRoutes,
    ...ShopsRoutes,
    ...OrdersRoutes,
    ...ProductsRoutes,
    ...CustomersRoutes,
    ...DashboardRoutes,
    ...CoreCatalogRoutes,
    ...UsersRoutes,
    ...AdministrationRoutes,
    ...OrdersAdminRoutes,
    ...SettingsRoutes,
    ...EdaVatRoutes,
    ...PaymentRoutes
  ]
})

function homePage() {
  if (isPrevelegedUser(window.localStorage.getItem("EUROTAX_USER_ROLE"))) {
    return {path: "/dashboard"}
  }
  
  if (isUser(window.localStorage.getItem("EUROTAX_USER_ROLE"))){
    return {path: "/shops"}
  } 
}

router.beforeEach((to, from, next) => {
  const onlyWhenLoggedIn = to.matched.some((record) => record.meta.auth),
        onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut),
        loggedIn = window.localStorage.getItem("EUROTAX_TOKEN"),
        
        requiredRoles = to.matched[0].meta.userRole,
        
        userRole =  window.localStorage.getItem("EUROTAX_USER_ROLE");
  let canGo;

  

  if (requiredRoles) {
    (requiredRoles.includes(userRole) || requiredRoles === "ANY") ? canGo = true : canGo = false;
  }
  
  
  if(to.path === "/login" && loggedIn) {
    return next(homePage());
  }
  
  if(to.path === "/") {
    return next(homePage());
  }
  
  if (onlyWhenLoggedIn && !loggedIn) {
    return next({
      path: "/login",
    });
  }
  
  if (loggedIn && onlyWhenLoggedOut) {
    return next("/");
  }

  if (canGo) {
    return next();
  }
  else {
    return next(homePage());
  }
  
});

export default router