export default {
  en: {
    required: "Required",
    phone: "Enter a valid phone number",
    email: "Enter a valid email",
    onlyAlphabeticChars: "Only alphabetic characters are allowed",
    onlyNumericChars: "Only numbers are allowed",
    onlyNumericAndSymbols: "Only numbers and symbols +, (, ) are allowed",
    exactChars: "Value should contain exactly {count} chars",
    inRangeChars: "Value should contain chars in range from {from} to {to}",
    vatNumber: "VAT number should be minimum 4 digits, maximum 15 digits",
    // nameIsRequired: "Name is required",
    // phoneIsRequired: "Phone is required",
    // emailIsRequired: "E-mail is required",
    // shopNameIsRequired: "Shop name is required",
    // descrIsRequired: "Description is required",
    invalidValue: "Invalid value",
    cn8NotValid: "CN8 not valid",
    invalidName: "Invalid name",
    valueMustBePositive: "Value must be positive",

    password: {
      match: "Password doesn't match",
      tooShort: "The password is too short",
      // noNumber: "The password must contain at least one number",
      // noLetters: "The password must contain at least one letter",
      // noSmallLetters: "The password must contain at least one lowercase letter",
      // noBigLetters: "The password must contain at least one uppercase letter",
      // noSpecialSymbols: "The password must contain at least one special symbol",
      // passwordDontMatch: "Passwords don't match",
    },
  },
  fr: {
    required: "Requis",
    phone: "Taper un numero de téléphone valide",
    email: "Taper une adresse email valide",
    onlyAlphabeticChars: "Uniquement caractères alphabétiques",
    onlyNumericChars: "Uniquement des chiffres",
    onlyNumericAndSymbols: "Sont permis chiffres et symboles + -  ( ) ",
    exactChars: "La valeur doit contenir exactement {count} caractères",
    inRangeChars:"La valeur doit contenir des caractères compris entre {from} et {to}",
    vatNumber: "Le numéro de TVA doit comporter au minimum 4 chiffres et au maximum 15 chiffres",
    // nameIsRequired: "Prénom est obligatoire",
    // phoneIsRequired: "Téléphone est obligatoire",
    // emailIsRequired: "E-mail est obligatoire",
    // shopNameIsRequired: "Nom de la Boutique obligatoire",
    // descrIsRequired: "Description est obligatoire",
    invalidValue: "Valeur non valide",
    cn8NotValid: "CN8 not valid",
    invalidName: "Invalid name",
    valueMustBePositive: "Value must be positive",

    password: {
      match: "Le mot de passe doit correspondre",
      tooShort: "Le mot de passe est trop court",
      // noNumber: "Le mot de passe doit contenir au moins un chiffre",
      // noLetters: "Le mot de passe doit contenir au moins une lettre",
      // noSmallLetters:
      //   "Le mot de passe doit contenir au moins une lettre minuscule",
      // noBigLetters:
      //   "Le mot de passe doit contenir au moins une lettre majuscule",
      // noSpecialSymbols:
      //   "Le mot de passe doit contenir au moins un caractère spéciale",
      // passwordDontMatch: "Le mot de passe ne correspond pas",
      
    },
  },
  spa: {
    required: "Requerido",
    phone: "Entrar un numero de telefono correcto",
    email: "Entrar un email validó",
    onlyAlphabeticChars: "Solo se permiten caracteres alfabéticos",
    onlyNumericChars: "Solo se permiten números",
    onlyNumericAndSymbols: "Solo se permiten números y símbolos +, (, )",
    exactChars: "El valor debe contener exactamente caracteres",
    inRangeChars: "El valor debe contener caracteres en el rango de {from} a {to}",
    vatNumber: "El número de IVA debe tener un mínimo de 4 dígitos, un máximo de 15 dígitos",
    // nameIsRequired: "Se requiere el nombre",
    // phoneIsRequired: "Se requiere teléfono",
    // emailIsRequired: "Se requiere email",
    // shopNameIsRequired: "El nombre de la tienda es obligatorio",
    // descrIsRequired: "Se requiere descripción",
    invalidValue: "Valor no válido",
    cn8NotValid: "CN8 not valid",
    invalidName: "Invalid name",
    valueMustBePositive: "Value must be positive",

    password: {
      match: "La contraseña no coincide",
      tooShort: "Clave demasiado corta",
      // noNumber: "La contraseña debe contener al menos un número",
      // noLetters: "La contraseña debe contener al menos una letra",
      // noSmallLetters: "La contraseña debe contener al menos una letra minúscula",
      // noBigLetters: "La contraseña debe contener al menos una letra mayúscula",
      // noSpecialSymbols: "La contraseña debe contener al menos un símbolo especial",
      // passwordDontMatch: "La contraseña no coincide",
    },
  },
  it: {
    required: "Obbligatorio",
    phone: "Inserisci un numero di telefono corretto",
    email: "Inserisci una mail valida",
    onlyAlphabeticChars: "Sono ammessi solo caratteri alfabetici",
    onlyNumericChars: "Sono ammessi solo numeri",
    onlyNumericAndSymbols: "Solo numeri e simboli +, (, ) sono ammessi",
    exactChars: "Il valore dovrebbe contenere esattamente {contare} caratteri",
    inRangeChars: "Il valore deve contenere caratteri compresi tra {da} e {a}",
    vatNumber: "Numero di partita IVA deve essere minimo 4 cifre, massimo 15 cifre",
    // nameIsRequired: "Name is required",
    // phoneIsRequired: "Phone is required",
    // emailIsRequired: "E-mail is required",
    // shopNameIsRequired: "Shop name is required",
    // descrIsRequired: "Description is required",
    invalidValue: "Valore non valido",
    cn8NotValid: "NC non valido",
    invalidName: "Il valore deve essere positivo",
    valueMustBePositive: "Il valore deve essere positivo",

    password: {
      match: "La password non coincide",
      tooShort: "Password troppo breve",
      // noNumber: "The password must contain at least one number",
      // noLetters: "The password must contain at least one letter",
      // noSmallLetters: "The password must contain at least one lowercase letter",
      // noBigLetters: "The password must contain at least one uppercase letter",
      // noSpecialSymbols: "The password must contain at least one special symbol",
      // passwordDontMatch: "Passwords don't match",
    },
  },
};
