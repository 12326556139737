import { IState, ProductMutationTypes } from "./types";

export const mutations = {
  [ProductMutationTypes.SetProductCollection](state, { items, page, limit, total }) {
    state.products = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [ProductMutationTypes.SetProduct](state, { data }) {
    state.product = data
  },

  [ProductMutationTypes.SetProductFile](state, { type = "", data = {} }) {
    state[type] = data
  },

  [ProductMutationTypes.SetProductReference](state, { reference }) {
    state.reference = reference
  },

  [ProductMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [ProductMutationTypes.ClearState](state) {
    Object.assign({}, state, IState)
  }
}