import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { i18n } from "./plugins/i18n"
import vuetify from "./plugins/vuetify"

import Default from "@/layouts/Default.vue"
import FullScreen from "@/layouts/FullScreen.vue"

Vue.component("DefaultLayout", Default)
Vue.component("FullScreenLayout", FullScreen)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app")
