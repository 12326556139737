import { Axios } from "./config.js";

export default class CategoryService {
  async getCollection({ page = 1, limit = 10 } = {}) {
    return await Axios.get("category/list", { params: { page, limit } });
  }

  async create({
    body = {
      name: ""
    },
  } = {}) {
    return await Axios.post("admin/category/create", body);
  }

  async edit({
    id = "",
    body = {
      name: ""
    },
  } = {}) {
    return await Axios.patch(`admin/category/${id}/update`, body);
  }

  async remove({ id }) {
    return await Axios.delete(`admin/category/${id}/delete`)
  }
}
