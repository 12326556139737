import { category } from "@/api";
import { i18n } from "@/plugins/i18n";

export const categoryModel = {
  id: "",
  name: ""
}

export const types = {
  CategoryNamespace: "category",
  CategoryActionTypes: {
    GetCategoryCollection: "getCategoryCollection",
    EditCategory: "editCategory",
    CreateCategory: "createCategory",
    RemoveCategory: "removeCategory",
  },
  CategoryMutationTypes: {
    SetCategoryCollection: "SET_CATEGORY_COLLECTION"
  }
}

export default {
  state: {
    items: [],
    item: {},
    page: 1,
    limit: 10,
    total: 0
  },

  actions: {
    async [types.CategoryActionTypes.GetCategoryCollection]({ dispatch, commit }, { page = 1, limit = 10 } = {}) {
      try {
        const { data } = await category.getCollection({ page, limit })
        commit(types.CategoryMutationTypes.SetCategoryCollection, { data })
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.CategoryActionTypes.CreateCategory]({ dispatch, commit }, { body } = {}) {
      try {
        await category.create({ body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.categoryCreated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }

    },

    async [types.CategoryActionTypes.EditCategory]({ dispatch, commit }, { id, body } = {}) {
      try {
        await category.edit({ id, body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.categoryUpdated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }

    },

    async [types.CategoryActionTypes.RemoveCategory]({ dispatch, commit }, { id = "" } = {}) {
      try {
        await category.remove({ id })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.categoryDeleted"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    }
  },

  mutations: {
    [types.CategoryMutationTypes.SetCategoryCollection](state, { data }) {
      Object.assign(state, data)
    },
  }
}