import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/locales/index";
import { defaultAxios, Axios } from "@/api/config";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages, // set locale messages
});

const loadedLanguages = []; // our default language that is preloaded

export function setI18nLanguage(lang) {
  localStorage.setItem("EUROTAX_LANG", lang);
  i18n.locale = lang;
  defaultAxios.defaults.headers.common["Accept-Language"] = lang;
  Axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

setI18nLanguage(localStorage.getItem("EUROTAX_LANG") || process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE );

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ "@/locales/index.js"
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default[lang]);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}
