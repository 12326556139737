export default {
  en: {
		reference: "Reference",
		productName: "Product name",
		productDescription: "Product description",
		productListShortDescription: "Product short description",
		abv: "ALC. VOL %",
		typeOfMaterial: "Type of material",
		litersPerUnit: "Liters per unit",
		weightOfTheUnit: "Weight of the unit (KG)",
		price: "Price",
		eanCode: "EAN",
		state: "State",


		descr: "Description",
		cn8: "CN8",
		productCreation: "Product creation",
		search: "Search",
		prodListDescr: "Product list short description",
		from: "From",
		to: "To",
		liters: "liters",
		customise: "Customise",
		prodEu: "Prod in EU",
		pressured: "Pressured (1-3 bars)",
		container: "Container",
		colour: "Colour",
		variety: "Variety",
		category: "Category",
		yes: "Yes",
		no: "No",
		white: "White",
		other: "Other",
		varietalWine: "Varietal wine",
		productsImport: "Products import",
		productEdit: "Edit product",
		invalid: "Invalid",
		valid: "Valid"
	},
	fr: {
		reference: "Référence",
		productName: "Nom produit",
		productDescription: "Description produit",
		productListShortDescription: "Description résumée produit",
		abv: "Degré / Vol. %",
		typeOfMaterial: "Type de matériel",
		litersPerUnit: "Litres / unité",
		weightOfTheUnit: "Poids de l'unité (Kg)",
		price: "Prix",
		eanCode: "EAN",
		state: "État",

		cn8: "Nomenclature",
		descr: "Déscription",
		productCreation: "Créer un produit",
		search: "Recherche",
		prodListDescr: "Description résumée liste produit",
		from: "De",
		to: "À",
		liters: "litres",
		customise: "Personnaliser",
		prodEu: "Produit en EU",
		pressured: "Pressurisé (1 - 3 bars)",
		container: "Conteneur",
		colour: "Couleur",
		variety: "Variété",
		category: "Catégorie",
		yes: "Oui",
		no: "Non",
		white: "Blanc",
		other: "Autre",
		varietalWine: "Vin variétal",
		productsImport: "Importer produits",
		productEdit: "Modifier le produit",
		invalid: "Non valide",
		valid: "Valide"
	},
	spa: {
		reference: "Referencia",
		productName: "Nombre del producto",
		productDescription: "Descripción del producto",
		prodListDescr: "Breve descripción de la lista de producto",
		abv: "Grado/ Vol %",
		typeOfMaterial: "Tipo de material",
		litersPerUnit: "Litros por unidad",
		weightOfTheUnit: "Peso de la unidad (Kg)",
		price: "Precio",
		eanCode: "EAN",
		state: "Estado",

		cn8: "Nomenclatura combinada",
		descr: "Descripción",
		productListShortDescription: "Breve descripción del producto",
		productCreation: "Crear un producto",
		search: "Buscar",
		from: "De",
		to: "Para",
		liters: "Litros",
		customise: "Personalizar",
		prodEu: "Prod en la UE",
		pressured: "Presurizado (1-3 bars)",
		container: "Envase",
		colour: "Color",
		variety: "Variedad",
		category: "Categorie",
		yes: "Sí",
		no: "No",
		white: "Blanco",
		other: "Otro",
		varietalWine: "Vino varietal",
		productsImport: "Importación de productos",
		productEdit: "Editar producto",
		invalid: "Inválido",
		valid: "Válido"
	},
	it: {
		reference: "Riferimento",
		productName: "Nome del prodotto",
		productDescription: "Descrizione del prodotto",
		productListShortDescription: "Breve descrizione del prodotto",
		abv: "ALC. VOL %",
		typeOfMaterial: "Tipo di materiale",
		litersPerUnit: "Litri per unità",
		weightOfTheUnit: "Peso dell'unità (KG)",
		price: "Prezzo",
		eanCode: "EAN",
		state: "Stato",


		descr: "Descrizione",
		cn8: "CN8",
		productCreation: "Crea un prodotto",
		search: "Cerca",
		prodListDescr: "Breve descrizione della lista del prodotto",
		from: "Da",
		to: "A",
		liters: "Litri",
		customise: "Personalizza",
		prodEu: "Prod. in UE",
		pressured: "Pressurizzato (1-3 bar)",
		container: "Container",
		colour: "Colore",
		variety: "Varietà",
		category: "Categoria",
		yes: "Sì",
		no: "No",
		white: "Bianco",
		other: "Altro",
		varietalWine: "Vino varietale",
		productsImport: "Importa prodotti",
		productEdit: "Modifica prodotto",
		invalid: "Non è valido",
		valid: "Valido"
	},
}