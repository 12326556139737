import { i18n } from "@/plugins/i18n";
import { shop } from "@/api";
import { ShopActionTypes, ShopMutationTypes } from "./types";

export const actions = {
  async [ShopActionTypes.GetShopCollection]({ dispatch, commit }, {
    params = {},
    admin = false,
    root = false
  } = {}) {
    commit(ShopMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await shop.getCollection({ admin, params })

      commit(ShopMutationTypes.SetShopCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.GetShop]({ dispatch, commit }, { sid = "" } = {}) {
    commit(ShopMutationTypes.SetLoadingState)

    try {
      const { data } = await shop.get({ sid })
      commit(ShopMutationTypes.SetShop, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.CreateShop]({ dispatch, commit }, { body = {} } = {}) {
    commit(ShopMutationTypes.SetLoadingState)
    try {
      const { data } = await shop.create({ body })
      commit(ShopMutationTypes.SetShop, { data })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.shopCreated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.UpdateShop]({ dispatch, commit }, { admin = false, sid = "", body = {} } = {}) {
    commit(ShopMutationTypes.SetLoadingState)
    try {
      const { data } = await shop.update({ admin, sid, body })

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.shopEdited"),
        { root: true }
      );
      commit(ShopMutationTypes.SetShop, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.RemoveShop]({ dispatch, commit }, { sid = "" } = {}) {
    commit(ShopMutationTypes.SetLoadingState)
    try {
      const { data } = await shop.remove({ sid })

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.shopDeleted"),
        { root: true }
      );
      commit(ShopMutationTypes.SetShop, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.GetShopCertificate]({ dispatch, commit }, { sid = "" } = {}) {
    commit(ShopMutationTypes.SetLoadingState)
    try {
      const { data } = await shop.downloadCertificate({ sid })

      commit(ShopMutationTypes.SetShopCertificate, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },

  async [ShopActionTypes.UploadShopCertificate]({ dispatch, commit }, { sid = "", body = {} } = {}) {
    commit(ShopMutationTypes.SetLoadingState)
    try {
      const { data } = await shop.uploadCertificate({ sid, body })

      commit(ShopMutationTypes.SetShopCertificate, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(ShopMutationTypes.SetLoadingState)
    }
  },



  [ShopActionTypes.ClearState]({ commit }) {
    commit(ShopActionTypes.ClearState)
  }
}