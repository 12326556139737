export default {
  en: {
		cn8: "CN8",
		description: "Description",
		abvMinMax: "ALC. VOL. % Min-Max",
		prodInEu: "Prod in EU", 
		pressured: "Pressured (1-3 bars)",
		container: "Container",
		colour: "Colour",
		variety: "Variety",
		category: "Category",
		defaultTaxFamily: "Default countries tax family",

		taxFamily: "Tax Family",

		createProduct: "Create a product",
		state: "State",
		alcVol: "ALC. VOL %",
		min: "Min",
		max: "Max",
		yes: "Yes",
		no: "No",
		white: "White",
		other: "Other",
		varietalWine: "Varietal wine",
		createAType: "Create a type",
	},
	fr: {
		cn8: "Nomenclature",
		description: "Déscription",
		abvMinMax: "Degré / Vol. % Min-Max",
		prodInEu: "Produit en EU",
		pressured: "Pressurisé (1 - 3 bars)",
		container: "Conteneur",
		colour: "Couleur",
		variety: "Variété",
		category: "Catégorie",
		defaultTaxFamily: "Familles fiscales pays par défaut",

		taxFamily: "Famille fiscale",

		createProduct: "Créer un produit",
		state: "État",
		alcVol: "Degré / Vol. %",
		min: "Min",
		max: "Max",
		yes: "Oui",
		no: "Non",
		white: "Blanc",
		other: "Autre",
		varietalWine: "Vin variétal",
		createAType: "Créer un type",
	},
	spa: {
		cn8: "Nomenclatura combinada",
		description: "Descripción",
		abvMinMax: "Grado/Vol % Min-Max",
		prodInEu: "Prod en la UE",
		pressured: "Presurizado (1-3 bars)",
		container: "Envase",
		colour: "Color",
		variety: "Variedad",
		category: "Categorie",
		defaultTaxFamily: "Familias ficales por defecto",

		taxFamily: "Familia fiscal",
		
		createProduct: "Crear un producto",
		state: "Estado",
		alcVol: "Grado/Vol %",
		min: "Min",
		max: "Max",
		yes: "Sí",
		no: "No",
		white: "Blanco",
		other: "Otro",
		varietalWine: "Vino varietal",
		createAType: "Crear un tipo",
	},
	it: {
		cn8: "NC",
		description: "Descrizione",
		abvMinMax: "ALC. VOL. % Min-Max",
		prodInEu: "Prod. in UE", 
		pressured: "Pressurizzato (1-3 bar)",
		container: "Container",
		colour: "Colore",
		variety: "Varietà",
		category: "Categoria",
		defaultTaxFamily: "Nucleo familiare predefinito",

		taxFamily: "Nucleo familiare",

		createProduct: "Crea un prodotto",
		state: "Stato",
		alcVol: "ALC. VOL %",
		min: "Min",
		max: "Max",
		yes: "Sì",
		no: "No",
		white: "Bianco",
		other: "Altro",
		varietalWine: "Vino varietale",
		createAType: "Crea un tipo",
	},
}