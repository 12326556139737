export const ProductNamespace = "product"

export const ProductActionTypes = {
  GetProductCollection: "getProductCollection",
  GetProduct: "getProduct",
  GetProductReference: "getReference",
  GetExcelExport: "getExcelExport",
  SearchProductCollection: "searchProductCollection",
  CreateProduct: "createProduct",
  UpdateProduct: "updateProduct",
  ClearState: "clearState"
}

export const ProductMutationTypes = {
  SetProductCollection: "setProductCollection",
  SetProduct: "setProduct",
  SetProductReference: "setReference",
  SetLoadingState: "setLoadingState",
  SetProductFile: "setProductFile",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  products: [],
  product: {},
  reference: "",
  page: 1,
  limit: 25,
  total: 0,
  excel: "",
}

export const IProduct = {
  id: "",
  cn8: "",
  reference: "",
  productName: "",
  productDescription: "",
  productListShortDescription: "",
  abv: "",
  typeOfMaterial: "",
  litersPerUnit: "",
  weightOfTheUnit: "",
  price: null,
  eanCode: "",
  category: "Wine",
  coreCatalogId: 0,
  state: true
}