import { CatalogMutationTypes } from "./types";

export const mutations = {
  [CatalogMutationTypes.SetCatalogCollection](state, { items, page, limit, total }) {
    state.catalogs = items
    state.page = page
    state.limit = limit
    state.total = total
  },

  [CatalogMutationTypes.SetCatalog](state, { data }) {
    state.catalog = data
    state.catalog.category = data.category.name
  },

  [CatalogMutationTypes.SetCatalogDescriptionCollection](state, { list }) {
    state.descriptions = list
  },

  [CatalogMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },
}