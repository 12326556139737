import { address } from "@/api";
import { i18n } from "@/plugins/i18n";

export default {
  state: {
    addresses: [],
    address: {},
    page: 1,
    limit: 10,
    total: 0
  },
  actions: {
    async getAddressCollection({ dispatch, commit }, { cid = "", page = 1, limit = 50, type } = {}) {
      try {
        const { data } = await address.getCollection({ cid, page, limit, type });

        commit("SET_ADDRESSES_COLLECTION", { ...data });
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async getAddress({ dispatch, commit }, { aid }) {
      try {
        const { data } = await address.get({ aid });
        commit("SET_ADDRESS", { data });
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async createAddress({ dispatch, commit }, { cid, body }) {
      try {
        await address.create({ cid, body });

        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.addressCreated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async updateAddress({ dispatch, commit }, { aid, body }) {
      try {
        await address.update({ aid, body });

        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.addressUpdated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    clearAddressState({ commit }) {
      commit("SET_ADDRESS", { data: {} });
    },
  },
  mutations: {
    SET_ADDRESSES_COLLECTION(state, { items = [] } = {}) {
      state.addresses = items;
    },

    SET_ADDRESS(state, { data = {} } = {}) {
      state.address = data;
    },
  },
};

const IAddress = {
  name: "",
  phone: "",
  country: "",
  area: "",
  city: "",
  type: "shipping_and_billing",
  address1: "",
  address2: "",
  zipcode: "",
}

export {
  IAddress
}