import { Axios } from "./config.js";
import clean from "clean-deep";

const basePath = "admin/user"
export default class UserService {
  async getCollection({
    params = {
      page: 1,
      limit: 25,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      roles: ""
    }
  } = {}) {
    return await Axios.get(`${basePath}/list`, {
      params: clean(params)
    });
  }

  async get({ uid = "" } = {}) {
    return await Axios.get(`${basePath}/${uid}`)
  }

  async create({ body = {} } = {}) {
    return await Axios.post(`${basePath}/create`, body)
  }

  async update({ uid = "", body = {} } = {}) {
    return await Axios.patch(`${basePath}/${uid}/update`, body)
  }
}
