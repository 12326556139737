import axios from "axios";
import router from "@/router/index.js";
import store from "@/store/index.js";
import ls from "local-storage";
import { UserService } from "@/api/userService.js";


export const defaultAxios = axios.create({
  withCredentials: true,
  baseURL:
    process.env.VUE_APP_BASE_URL
});

defaultAxios.interceptors.request.use(
  (config) => {
    const token = ls.get("EUROTAX_TOKEN");
    const { headers, url } = config

    if (token) {
      Object.assign(headers, { Authorization: `Bearer ${token}` })
    }

    if (url.includes("upload-certificate-of-incorporate")) {
      Object.assign(headers, { "Content-Type": "multipart/form-data" })
      return config
    }

    Object.assign(headers, { "Content-Type": "application/json" })

    return config;
  },
  (error) => {
    throw error;
  }
);

defaultAxios.interceptors.response.use(
  (response) => {
    const { url } = response.config
    const { data } = response

    if (url.includes("login")) {
      ls.set("EUROTAX_TOKEN", data.token)
      ls.set("EUROTAX_TOKEN_REFRESH", data.refresh_token)
    }

    return response;
  },
  (error) => {
    const { response } = error

    if (response.status === 403) {
      store.commit("snackbarModule/SET_ERROR_API", response.status);
      ls.clear();
      router.push({ name: "Login" });
    }

    if (response.status === 401 && response.data.message === "JWT Token not found" || response.data.message === "Invalid JWT Token") {
      store.commit("snackbarModule/SET_ERROR_API", response.status);
      ls.clear();
      router.push({ name: "Login" });
    }

    if (response.status === 401 && response.data.message === "Expired JWT Token") {
      UserService.refreshToken(ls.get("EUROTAX_TOKEN_REFRESH")).then(res => {
        const { status, data } = res
        if (status === 200) {
          ls.set("EUROTAX_TOKEN", data.token);
          return defaultAxios(response.config)
        }
      }).then(() => {
        const currentPath = router?.history?.current?.path || ""
        if (!currentPath) return
        router.go()
      }).catch(() => {
        ls.clear();
        router.push({ name: "Login" });
      })
    }

    throw error
  }
);

export const Axios = defaultAxios;
