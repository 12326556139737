export const OrderNamespace = "order"

export const OrderActionTypes = {
  GetOrderCollection: "getOrderCollection",
  GetOrder: "getOrder",
  GetOrderPaymentStatus: "getOrderPaymentStatus",
  GetOrderExcelDocument: "getOrderExcelDocument",
  SearchOrderCollection: "searchOrderCollection",
  CreateOrderPayment: "createOrderPayment",
  GenerateOrderDocuments: "generateDocuments",
  GetOrderFile: "getFile",
  CreateOrder: "createOrder",
  UpdateOrder: "updateOrder",
  RemoveOrder: "removeOrder",
  UpdateOrderStatus: "updateOrderStatus",
  CalculateOrderPrice: "calculateOrderPrice",
  ClearState: "clearState"
}

export const OrderMutationTypes = {
  SetOrderCollection: "setOrderCollection",
  SetOrder: "setOrder",
  SetOrderFile: "setOrderFile",
  SetOrderPayment: "setOrderPayment",
  SetOrderPaymentStatus: "setOrderPaymentStatus",
  SetOrderReference: "setReference",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  orders: [],
  order: {},
  payment: {},
  saad: "",
  invoice: "",
  excel: "",
  loading: false,
  page: 1,
  limit: 25,
  total: 0,

}

export const IOrder = {
  shippingMethod: "",
  shippingCountry: "",
  approxDeliveryDate: "",
  paymentDate: "",
  goodsTotal: 0,
  eurotaxServiceTotal: 0,
  orderPriceBreakdown: {
    feePerBottleTotal: null,
    fixedFeePerOrder: null,
    exciseAmountTotal: null,
    cardPaymentFeePercentage: null,
    cardPaymentFeePercentageCalculated: null,
    cardPaymentFixedFee: null,
    vatRate: null,
    vatAmount: null
  },
  totalPayment: 0,
  customerData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  },
  billingAddressData: {
    name: "",
    type: "",
    phone: "",
    country: "",
    area: "",
    city: "",
    address1: "",
    address2: "",
    zipcode: ""
  },
  shippingAddressData: {
    name: "",
    type: "",
    phone: "",
    country: "",
    area: "",
    city: "",
    address1: "",
    address2: "",
    zipcode: ""
  },
  productsData: [],
  status: "order_creation"
}