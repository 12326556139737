export default [
  {
    path: "/payment-redirect/stripe/:id/success",
    name: "PaymentPage",
    component: () => import("../views/Payment/PaymentPage"),
    meta: {
      userRole: "ANY",
      layout: "Default"
    }
  },
]