export default [
{
	path: "/shops/:id/customers",
	name: "CustomersPage",
	component: () => import("../views/Customer/Customers/CustomersPage"),
	meta: {
		auth: true,
		userRole: "ANY",
		layout: "Default"
	}
},
{
	path: "/customers-import",
	name: "CustomersImportPage",
	component: () => import("../views/Customer/Customers/CustomersImportPage"),
	meta: {
		auth: true,
		userRole: "ANY",
		layout: "Default"
	}
},
{
	path: "/shops/:id/customers/create-customer",
	name: "CreateCustomerPage",
	component: () => import("../views/Customer/Customers/CustomerPage"),
	meta: {
		auth: true,
		userRole: "ANY",
		layout: "Default"
	}
},
{
	path: "/shops/:id/customers/:cid/edit-customer",
	name: "EditCustomerPage",
	component: () => import("../views/Customer/Customers/CustomerPage"),
	meta: {
		auth: true,
		userRole: "ANY",
		layout: "Default"
	}
},
]