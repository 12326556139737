import Address from "./Address";
import Product from "./Product";
import Catalog from "./Catalog";
import ImportTax from "./ImportTax";
import FamilyTax from "./FamilyTax";
import Customer from "./Customer";
import Category from "./Category";
import Order from "./Order";
import Country from "./Country";
import Carrier from "./Carrier";
import Shop from "./Shop";
import Dashboard from "./Dashboard"
import Settings from "./settings"
import User from "./User";
import Profile from "./Profile";

const address = new Address()
const product = new Product()
const catalog = new Catalog()
const importTax = new ImportTax()
const familyTax = new FamilyTax()
const customer = new Customer()
const category = new Category()
const order = new Order()
const country = new Country()
const carrier = new Carrier()
const shop = new Shop()
const dashboard = new Dashboard()
const settings = new Settings()
const user = new User()
const profile = new Profile()

export {
  address,
  product,
  catalog,
  importTax,
  familyTax,
  customer,
  category,
  order,
  country,
  carrier,
  shop,
  dashboard,
  settings,
  user,
  profile
}