export default {
  en: {
    termsAndConditions: "GENERAL CONDITIONS OF SALE",
    loginPage: {
      login: "login",
      email: "Email",
      password: "Password",
      forgotPassword: "Forgot password?",
      captcha: "Captcha",
      dontHaveAnAccount: "I don't have an account",
      weSentConfirmEmail: "We sent you the confirmation email. Please confirm the reset",
      weSentVerificationEmail: "Please verify your email address, by the link we sent to you",
      verificationSuccess: "Verification was successful",
      messageSent: "Message has been sent successfully"
    },
    signUpPage: {
      register: "Register",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      captcha: "Captcha",
      agreeWithTerms: "I agree whith terms and conditions",
      haveAnAccount: "I have an account",
    },
    profilePage: {
      profile: "Profile",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone",
      changePass: "Change password",
      oldPassword: "Old password",
      oldPasswordIncorrect: "The old password you have entered is incorrect",
      passwordDoesntMatch: "Password doesn't match",
      passwordSuccessfullyChanged: "Password successfully changed"
    },
  },
  fr: {
    termsAndConditions: "CONDITIONS GENERALES DE VENTE",
    loginPage: {
      login: "Identifiant",
      email: "Courriel",
      password: "Mot de passe",
      forgotPassword: "Mot de passe oublié?",
      captcha: "Captcha",
      dontHaveAnAccount: "Je n'ai pas de compte",
      weSentConfirmEmail: "Nous vous avons envoyé l'e-mail de confirmation. Veuillez confirmer la réinitialisation",
      weSentVerificationEmail: "Merci de vérifier votre adresse email, dans le lien envoyé",
      verificationSuccess: "Vérification réussie",
      messageSent: "Message envoyé avec succès"
    },
    signUpPage: {
      register: "Enregistrement",
      firstName: "Prénom",
      lastName: "Nom de famille",
      phone: "Téléphone",
      email: "Courriel",
      password: "Mot de passe",
      confirmPassword: "Confirmer mot de passe",
      captcha: "Captcha",
      agreeWithTerms: "Consentement aux CGV",
      haveAnAccount: "J'ai un compte",
    },
    profilePage: {
      profile: "Profil",
      email: "Courriel",
      firstName: "Prénom",
      lastName: "Nom de famille",
      phone: "Téléphone",
      changePass: "Modifier mot de passe",
      oldPassword: "Ancien mot de passe",
      oldPasswordIncorrect: "L'ancien mot de passe que vous avez entré est incorrectt",
      passwordDoesntMatch: "Le mot de passe ne correspond pas",
      passwordSuccessfullyChanged: "Mote de passe mis à jour"
    },
  },
  spa: {
    termsAndConditions: "CONDICIONES GENERALES DE VENTA",
    loginPage: {
      login: "Acceso",
      email: "Correo electrónico",
      password: "Contraseña",
      forgotPassword: "Contraseña olvidada?",
      captcha: "Captcha",
      dontHaveAnAccount: "No tengo cuenta",
      weSentConfirmEmail: "Le hemos enviado un email de confirmación. Por favor, confirme el reinicio",
      weSentVerificationEmail: "Please verify your email address, by the link we sent to you", 
      verificationSuccess: "Verificación  exitosa",
      messageSent: "Message has been sent successfully"
    },
    signUpPage: {
      register: "Registro",
      firstName: "Nombre",
      lastName: "Apellido",
      phone: "Teléfono",
      email: "Correo electrónico",
      password: "Contraseña",
      confirmPassword: "Confirmar contraseña",
      captcha: "Captcha",
      agreeWithTerms: "Acepto los términos y condiciones",
      haveAnAccount: "Tengo cuenta",
    },
    profilePage: {
      profile: "Perfil",
      email: "Correo electrónico",
      firstName: "Nombre",
      lastName: "Apellido",
      phone: "Teléfono",
      changePass: "Cambiar la contraseña",
      oldPassword: "Contraseña anterior",
      oldPasswordIncorrect: "La antigua contraseña que ha introducido es incorrecta",
      passwordDoesntMatch: "La contraseña no coincide",
      passwordSuccessfullyChanged: "Contraseña cambiada correctamente"
    },
  },

  it: {
    termsAndConditions: "GENERAL CONDITIONS OF SALE",
    loginPage: {
      login: "Accedi",
      email: "Email",
      password: "Password",
      forgotPassword: "Hai dimenticato la password?",
      captcha: "Captcha",
      dontHaveAnAccount: "Non ho un account",
      weSentConfirmEmail: "Ti abbiamo inviato una mail di conferma. Per favore, conferma il ripristino",
      weSentVerificationEmail: "Per favore, verifica il tuo indirizzo e-mail dal link che ti abbiamo inviato",
      verificationSuccess: "Conferma effettuata con successo",
      messageSent: "Il messaggio è stato inviato con successo"
    },
    signUpPage: {
      register: "Registrati",
      firstName: "Nome",
      lastName: "Cognome",
      phone: "Telefono",
      email: "Email",
      password: "Password",
      confirmPassword: "Conferma password",
      captcha: "Captcha",
      agreeWithTerms: "Accetto i termini e le condizioni",
      haveAnAccount: "Ho un account",
    },
    profilePage: {
      profile: "Profilo",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Email",
      phone: "Telefono",
      changePass: "Modifica la password",
      oldPassword: "Vecchia password",
      oldPasswordIncorrect: "La vecchia password che hai inserito non è corretta",
      passwordDoesntMatch: "La password non coincide",
      passwordSuccessfullyChanged: "Password modificata con successo"
    },
  },
};