import { carrier } from "@/api/";
import { CarrierActionTypes, CarrierMutationTypes } from "./types";

export const actions = {
  async [CarrierActionTypes.GetCarrierCollection]({ dispatch, commit }, { root = false } = {}) {
    commit(CarrierMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await carrier.getCollection()
      
      commit(CarrierMutationTypes.SetCarrierCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CarrierMutationTypes.SetLoadingState)
    }
  },
}