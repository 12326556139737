import { i18n } from "@/plugins/i18n";
import { catalog } from "@/api";
import { CatalogActionTypes, CatalogMutationTypes } from "./types";

export const actions = {
  async [CatalogActionTypes.GetCatalogCollection]({ dispatch, commit }, { params = {} } = {}) {
    commit(CatalogMutationTypes.SetLoadingState)
    try {
      const { data } = await catalog.getCollection({ params })

      commit(CatalogMutationTypes.SetCatalogCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CatalogMutationTypes.SetLoadingState)
    }
  },

  async [CatalogActionTypes.SearchCatalogCollection]({ dispatch, commit }, { query = "" } = {}) {
    try {
      const { data } = await catalog.search({ query })

      commit(CatalogMutationTypes.SetCatalogCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CatalogActionTypes.GetCatalog]({ dispatch, commit }, { pid = "" } = {}) {
    commit(CatalogMutationTypes.SetLoadingState)

    try {
      const { data } = await catalog.get({ cid: pid })
      Object.assign(data, { variety: data.variety.split(",") })

      commit(CatalogMutationTypes.SetCatalog, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CatalogMutationTypes.SetLoadingState)
    }
  },

  async [CatalogActionTypes.CreateCatalog]({ dispatch, commit }, { body = {}, user = false } = {}) {
    commit(CatalogMutationTypes.SetLoadingState)
    try {
      const { data } = await catalog.create({ body, user })
      Object.assign(data, { variety: data.variety.split(",") })

      if (user) {
        commit(CatalogMutationTypes.SetCatalog, { data })
      }

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.coreCatalogCreated"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CatalogMutationTypes.SetLoadingState)
    }
  },

  async [CatalogActionTypes.UpdateCatalog]({ dispatch, commit }, { pid = "", body = {} } = {}) {
    commit(CatalogMutationTypes.SetLoadingState)

    try {
      if (typeof body.category !== "string") {
        body.category = body.category.name
      }
      if (typeof body.defaultTaxFamily !== "string") {
        body.defaultTaxFamily = body.defaultTaxFamily.name
      }
      const { data } = await catalog.update({ pid, body })
      // TODO poop coz of back end
      Object.assign(data, { variety: data.variety.split(",") })
      // TODO coz of back end
      //IDK if it's a mistake or not, but in this situation, the error no longer appears ->
      if (typeof body.category !== "string") {
        data.category = data.category.name
      }

      if (typeof body.defaultTaxFamily !== "string") {
        data.defaultTaxFamily = data.defaultTaxFamily.name
      }

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.coreCatalogUpdated"),
        { root: true }
      );
      commit("SET_CATALOG", { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(CatalogMutationTypes.SetLoadingState)
    }
  },

  async [CatalogActionTypes.MatchCatalog]({ dispatch, commit }, { body = {} } = {}) {
    try {

      const { data } = await catalog.match({ body })

      if (!data) {
        await dispatch(CatalogActionTypes.CreateCatalog, { body, user: true })
        return
      }

      commit(CatalogMutationTypes.SetCatalog, { data })
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.matchFound"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CatalogActionTypes.GetCatalogDescriptionCollection]({ dispatch, commit }) {
    try {
      const { data } = await catalog.getDescriptionCollection()
      commit(CatalogMutationTypes.SetCatalogDescriptionCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  async [CatalogActionTypes.RemoveCatalog]({ dispatch, commit }, id) {
    try {
      await catalog.remove(id)
      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.typeDeleteSuccess"),
        { root: true }
      );
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  }
}