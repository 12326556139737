export default {
  en: {
		id: "ID",
		firstName: "First name",
		lastName: "Last name",
		email: "Email",
		eurotaxServiceValue: "Eurotax service (per unit)",
		accountState: "Account state",
		phone: "Phone",
		locale: "Language",
		shopName: "Shops",
		
		nameSurname: "Name, surname",
		name: "Name",
		user: "User",
		editCustomer: "Edit a customer",
		editUser: "Edit user",
		createUser: "Create user",
		changePass: "Change password",
		password: "Password",
    confirmPassword: "Confirm Password",
	},
	fr: {
		id: "ID",
		firstName: "Prénom",
		lastName: "Nom de famille",	
		email: "Courriel",
		eurotaxServiceValue: "Service Eurotax (par unité)",
		accountState: "Etat compte",
		phone: "Téléphone",
		locale: "Langue",
		shopName: "Boutiques",
		
		nameSurname: "Prénom, nom",
		name: "Prénom",
		user: "Utilisateur",
		editCustomer: "Editer client",
		editUser: "Editer utilisateur",
		changePass: "Modifier mot de passe",
		password: "Mot de passe",
    confirmPassword: "Confirmer mot de passe",
	},
	spa: {
		id: "ID",
		firstName: "Nombre",
		lastName: "Apellido",
		email: "Correo electrónico",
		eurotaxServiceValue: "Tarifas servicio Eurotax por defecto",
		accountState: "Estado de la cuenta",
		phone: "Teléfono",
		locale: "Idioma",
		shopName: "Tiendas",

		nameSurname: "Nombre y apellido",
		name: "Nombre",
		user: "Usuario",
		editCustomer: "Editar cliente",
		editUser: "Editar usuario",
		changePass: "Cambiar la contraseña",
		password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
	},
	it: {
		id: "ID",
		firstName: "Nome",
		lastName: "Cognome",
		email: "Email",
		eurotaxServiceValue: "Servizio Eurotax (per unità)",
		accountState: "Stato dell'account",
		phone: "Telefono",
		locale: "Lingua",
		shopName: "Negozi",
		
		nameSurname: "Nome e cognome",
		name: "Nome",
		user: "Utente",
		editCustomer: "Modifica cliente",
		editUser: "Modifica utente",
		createUser: "Crea un utente",
		changePass: "Modifica la password",
		password: "Password",
    confirmPassword: "Conferma password",
	},
}