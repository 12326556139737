import { Axios } from "./config.js";

export default class SettingsService {
  async getDefaultEurotaxServicePrice() {
    return await Axios.get("/settings/default-eurotax-service-price");
  }

  async updateDefaultEurotaxServicePrice({ defaultEurotaxServicePrice = 0 } = {}) {
    return await Axios.patch("admin/settings/default-eurotax-service-price/update", { defaultEurotaxServicePrice })
  }
}