// TODO delete from actions
import { i18n } from "@/plugins/i18n";
import { user } from "@/api/";
import { UserActionTypes, UserMutationTypes } from "./types";

export const actions = {
  async [UserActionTypes.GetUserCollection]({ dispatch, commit }, {
    params = {},
    root = false
  } = {}) {
    commit(UserMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await user.getCollection({ params })

      commit(UserMutationTypes.SetUserCollection, { ...data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState)
    }
  },

  async [UserActionTypes.GetUser]({ dispatch, commit }, { uid = "", root = false } = {}) {
    commit(UserMutationTypes.SetLoadingState, { root })
    try {
      const { data } = await user.get({ uid })
      // coz of diff model on back
      Object.assign(data, { role: data.roles[0] })

      commit(UserMutationTypes.SetUser, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState)
    }
  },

  async [UserActionTypes.CreateUser]({ dispatch, commit }, { body = {}, root = false } = {}) {
    commit(UserMutationTypes.SetLoadingState, { root })

    try {
      const { data } = await user.create({ body })

      commit(UserMutationTypes.SetUser, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState)
    }
  },

  async [UserActionTypes.UpdateUser]({ dispatch, commit }, { uid = "", body = {} } = {}) {
    try {
      const { data } = await user.update({ uid, body })

      commit(
        "snackbarModule/SET_SUCCESS_MESSAGE",
        i18n.t("snackbar.success.userUpdated"),
        { root: true }
      );
      commit(UserMutationTypes.SetUser, { data })
    } catch (error) {
      dispatch("snackbarModule/setErrorApi", error, { root: true });
    }
  },

  [UserActionTypes.ClearState]({ commit }) {
    commit(UserActionTypes.ClearState)
  }
}