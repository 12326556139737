import { importTax } from "@/api";
import { i18n } from "@/plugins/i18n";

export const types = {
  ImportTaxNamespace: "importTax",
  ImportTaxActionTypes: {
    GetImportTaxCollection: "getImportTaxCollection",
    GetImportTax: "getImportTax",
    CreateImportTax: "createImportTax",
    EditImportTax: "editImportTax",
    RemoveImportTax: "removeImportTax"
  },
  ImportTaxMutationTypes: {
    SetTaxCollection: "SET_TAX_COLLECTION",
    SetTax: "SET_TAX"
  }
}

export default {
  state: {
    items: [],
    item: {},
    page: 1,
    limit: 0,
    total: 0
  },

  actions: {
    async [types.ImportTaxActionTypes.GetImportTaxCollection]({ dispatch, commit }, page) {
      try {
        const { data } = await importTax.getCollection(page)
        commit(types.ImportTaxMutationTypes.SetTaxCollection, { ...data })
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.ImportTaxActionTypes.GetImportTax]({ dispatch, commit }, { taxFamily = "", country = "" } = {}) {
      try {
        const { data } = await importTax.get({ taxFamily, country })
        commit(types.ImportTaxMutationTypes.SetTax, { data })
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.ImportTaxActionTypes.CreateImportTax]({ dispatch, commit }, { body = {} } = {}) {
      try {
        await importTax.create({ body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.importTaxCreated"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.ImportTaxActionTypes.EditImportTax]({ dispatch, commit }, { id = "", body = {} } = {}) {
      try {
        await importTax.update({ id, body })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          "Successfully updated",
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    },

    async [types.ImportTaxActionTypes.RemoveImportTax]({ dispatch, commit }, { id = "" } = {}) {
      try {
        await importTax.remove({ id })
        commit(
          "snackbarModule/SET_SUCCESS_MESSAGE",
          i18n.t("snackbar.success.importTaxDeleted"),
          { root: true }
        );
      } catch (error) {
        dispatch("snackbarModule/setErrorApi", error, { root: true });
      }
    }
  },

  mutations: {
    [types.ImportTaxMutationTypes.SetTaxCollection](state, { items, total, limit, page }) {
      state.items = items
      state.total = total
      state.limit = limit
      state.page = page
    },
    [types.ImportTaxMutationTypes.SetTax](state, { data }) {
      state.item = data
    }
  }
}

export const importTaxModel = {
  id: "",
  country: "",
  abvMin: 0,
  abvMax: 0,
  exciseType: "HL",
  exciseRate: 0,
  currency: "EUR",
  vat: 0,
  taxFamily: "",
}