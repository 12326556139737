const ROLES = {
  ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_EUROTAX: "ROLE_EUROTAX",
  ROLE_CUSTOMER: "ROLE_CUSTOMER",
  ROLE_USER: "ROLE_USER",
  ROLE_CO_MANAGER: "ROLE_CO_MANAGER",
}

function isPrevelegedUser(role) {
  return [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_EUROTAX, ROLES.ROLE_CUSTOMER, ROLES.ROLE_CO_MANAGER].indexOf(role) >= 0;
}
function isAdmin(role) {
  return [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_EUROTAX].indexOf(role) >= 0;
}
function isUser(role) {
  return [ROLES.ROLE_USER] == role
}
function isCustomerOrUser(role) {
  return [ROLES.ROLE_USER, ROLES.ROLE_CUSTOMER].indexOf(role) >= 0
}
function isSuperAdmin(role) {
  return [ROLES.ROLE_SUPER_ADMIN].indexOf(role) >= 0
}


export {ROLES, isPrevelegedUser, isAdmin, isUser, isCustomerOrUser, isSuperAdmin};