import { CarrierMutationTypes } from "./types";

export const mutations = {
  [CarrierMutationTypes.SetCarrierCollection](state, { list }) {
    state.list = list
  },

  [CarrierMutationTypes.SetLoadingState](state) {
    state.loading = !state.loading
  },

  [CarrierMutationTypes.ClearState](state) {
    state.loading = false
    state.carriers = []
  }
}