import { Axios } from "./config.js";
import clean from "clean-deep";

export default class OrderService {
  async getCollection({
    sid = "",
    params = {
      page: 1,
      limit: 25,
      status: "",
      id: "",
      customer: "",
      createdAt: "",
      approxDeliveryDate: ""
    },
    admin = false,
  } = {}) {
    if (admin) {
      return await Axios.get("admin/orders/list", { params: clean(params) });
    }
    return await Axios.get(`orders/${sid}/list`, { params: clean(params) });
  }

  async get({ oid = "" } = {}) {
    return await Axios.get(`orders/${oid}`)
  }

  async getSaadFile({ oid = "" } = {}) {
    return await Axios.get(`orders/${oid}/download-saad-file`, {
      responseType: "blob"
    })
  }

  async getInvoiceFile({ oid = "" } = {}) {
    return await Axios.get(`orders/${oid}/download-invoice`, {
      responseType: "blob"
    })
  }

  async create({
    sid = "",
    body = {
      shippingMethod: "",
      approxDeliveryDate: "",
      goodsTotal: 0,
      totalPayment: 0,
      customerData: {},
      billingAddressData: {},
      shippingAddressData: {},
      productsData: []
    }
  } = {}) {
    return await Axios.post(`orders/${sid}/create`, body);
  }

  async update({
    oid = "",
    body = {
      shippingMethod: "",
      approxDeliveryDate: "",
      goodsTotal: 0,
      totalPayment: 0,
      customerData: {},
      billingAddressData: {},
      shippingAddressData: {},
      productsData: []
    }
  } = {}) {
    return await Axios.patch(`orders/${oid}/update`, body);
  }

  async generateDocuments({ oid = "" } = {}) {
    return await Axios.post(`orders/${oid}/generate-documents`)
  }

  async downloadExcel({
    params = {
      status: "",
      id: "",
      customer: "",
      createdAt: "",
      approxDeliveryDate: ""
    },
  } = {}) {
    return await Axios.post("orders/download-excel", null, {
      params: clean(params),
      responseType: "blob",
    })
  }

  async getPayment({ oid = "" } = {}) {
    return await Axios.get(`orders/${oid}/payment`)
  }

  async getPaymentStatus({ tid = "" } = {}) {
    return await Axios.get(`get-status/stripe/${tid}`)
  }

  async updateOrderStatus({ oid = "", status } = {}) {
    return await Axios.patch(`orders/${oid}/update-status`, { status })
  }

  async calculatePrice({
    body = {
      shippingMethod: "",
      approxDeliveryDate: "",
      goodsTotal: 0,
      totalPayment: 0,
      customerData: {},
      billingAddressData: {},
      shippingAddressData: {},
      productsData: []
    }
  } = {}) {
    return await Axios.post("orders/calculate-price", body);
  }


}
