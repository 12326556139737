export default {
  en: {
		theShopsAreYourSalesChannels: "The shops are your sales channels. They are made to separate your catalogs and customers databases. Each shop is a sales channel with own products and customers",

		selectTheNomenclature: "Select the Nomenclature / CN8 of the product you want to add to your shop catalog.",
		allTheProductsYouAddedHere: "All the products you add here will be added to your shop and will be available for your deliveries.",


		commercialInfo: "Commercial info: ",
		pleaseProvideSomeCommercialInformation: "Please provide some commercial information about your product.",

		theBrandNameOfYourProduct: "The brand/name of your product as it has to appear in your products list",

		theFullDescriptionOfYourProduct: "The full description of your product, as it would appear in the detailed information or product info sheet.",

		pleaseProvideAShortDescr: "Please provide a short description that completes the",
		forCustomersPurposes: " for customs purposes",

		customersCompulsoryInfo: "Customs compulsory info:",
		pleaseProvideSomeLegalInformation: "Please provide some legal information about your product and packaging.",

		taxableAmountBeforeTaxes: "Taxable amount before taxes",

		beCompliantIsRequiredBy: "BeCompliant is required by authorities to keep an exhaustive register of the billed and shipped persons. In consequence, adding a new customer is split into two steps:",
		createAnAddress: "Create an address",
		assignToBillOrShipp: "assign to 'billing' and/or 'shipping'",
		pleaseSendUsAMessage: "Please send us a message on the 'SUPPORT' section on your top right menu if you need some help",

		presetInfo: "Preset info:",
		areaWithDefaultPresets: "Area with default presets based on the CN8 you introduced. Do not modify unless you have some knowledge about customs codes and excise duties and tax. Any change to this section will be reviewed manually before allowing dispatch. If you think this area is not correctly filled but you need some support, please send us a message via the “SUPPORT” section on your top-right menu.",

		paymentConfirmed: "Payment confirmed you can close this page."
	},
	fr: {
		theShopsAreYourSalesChannels: "The shops are your sales channels. They are made to separate your catalogs and customers databases. Each shop is a sales channel with own products and customers",

		selectTheNomenclature: "Select the Nomenclature / CN8 of the product you want to add to your shop catalog.",
		allTheProductsYouAddedHere: "All the products you add here will be added to your shop and will be available for your deliveries.",


		commercialInfo: "Commercial info: ",
		pleaseProvideSomeCommercialInformation: "Please provide some commercial information about your product.",

		theBrandNameOfYourProduct: "The brand/name of your product as it has to appear in your products list",

		theFullDescriptionOfYourProduct: "The full description of your product, as it would appear in the detailed information or product info sheet.",

		pleaseProvideAShortDescr: "Please provide a short description that completes the",
		forCustomersPurposes: " for customs purposes",

		customersCompulsoryInfo: "Customs compulsory info:",
		pleaseProvideSomeLegalInformation: "Please provide some legal information about your product and packaging.",

		taxableAmountBeforeTaxes: "Taxable amount before taxes",

		beCompliantIsRequiredBy: "BeCompliant is required by authorities to keep an exhaustive register of the billed and shipped persons. In consequence, adding a new customer is split into two steps:",
		createAnAddress: "Create an address",
		assignToBillOrShipp: "assign to 'billing' and/or 'shipping'",
		pleaseSendUsAMessage: "Please send us a message on the 'SUPPORT' section on your top right menu if you need some help",

		presetInfo: "Preset info:",
		areaWithDefaultPresets: "Area with default presets based on the CN8 you introduced. Do not modify unless you have some knowledge about customs codes and excise duties and tax. Any change to this section will be reviewed manually before allowing dispatch. If you think this area is not correctly filled but you need some support, please send us a message via the “SUPPORT” section on your top-right menu.",

		paymentConfirmed: "Payment confirmed you can close this page."
	},
	spa: {
		theShopsAreYourSalesChannels: "The shops are your sales channels. They are made to separate your catalogs and customers databases. Each shop is a sales channel with own products and customers",

		selectTheNomenclature: "Select the Nomenclature / CN8 of the product you want to add to your shop catalog.",
		allTheProductsYouAddedHere: "All the products you add here will be added to your shop and will be available for your deliveries.",


		commercialInfo: "Commercial info: ",
		pleaseProvideSomeCommercialInformation: "Please provide some commercial information about your product.",

		theBrandNameOfYourProduct: "The brand/name of your product as it has to appear in your products list",

		theFullDescriptionOfYourProduct: "The full description of your product, as it would appear in the detailed information or product info sheet.",

		pleaseProvideAShortDescr: "Please provide a short description that completes the",
		forCustomersPurposes: " for customs purposes",

		customersCompulsoryInfo: "Customs compulsory info:",
		pleaseProvideSomeLegalInformation: "Please provide some legal information about your product and packaging.",

		taxableAmountBeforeTaxes: "Taxable amount before taxes",

		beCompliantIsRequiredBy: "BeCompliant is required by authorities to keep an exhaustive register of the billed and shipped persons. In consequence, adding a new customer is split into two steps:",
		createAnAddress: "Create an address",
		assignToBillOrShipp: "assign to 'billing' and/or 'shipping'",
		pleaseSendUsAMessage: "Please send us a message on the 'SUPPORT' section on your top right menu if you need some help",

		presetInfo: "Preset info:",
		areaWithDefaultPresets: "Area with default presets based on the CN8 you introduced. Do not modify unless you have some knowledge about customs codes and excise duties and tax. Any change to this section will be reviewed manually before allowing dispatch. If you think this area is not correctly filled but you need some support, please send us a message via the “SUPPORT” section on your top-right menu.",

		paymentConfirmed: "Payment confirmed you can close this page."
	},
  it: {
		theShopsAreYourSalesChannels: "I negozi sono i tuoi canali di vendita. Servono per separare i tuoi cataloghi e i database dei clienti. Ogni negozio è un canale di vendita con i propri prodotti e clienti.",

		selectTheNomenclature: "Seleziona la Denominazione / NC del prodotto che vuoi aggiungere al tuo catalogo.",
		allTheProductsYouAddedHere: "Ogni prodotto che aggiungi qui verrà aggiunto al tuo negozio e sarà disponibile per le tue spedizioni.",


		commercialInfo: "Informazione commerciale: ",
		pleaseProvideSomeCommercialInformation: "si prega di inserire delle informazioni commerciali sul prodotto.",

		theBrandNameOfYourProduct: "la marca/nome del tuo prodotto deve apparire nella lista dei prodotti",

		theFullDescriptionOfYourProduct: "La descrizione completa del tuo prodotto dovrebbe apparire nell'informazione dettagliata o nella scheda del prodotto.",

		pleaseProvideAShortDescr: "Per favore, inserisci una breve descrizione per completare il",
		forCustomersPurposes: " ai fini doganali.",

		customersCompulsoryInfo: "Informazione doganale obbligatoria:",
		pleaseProvideSomeLegalInformation: "si prega di inserire delle informazioni legali sul prodotto e imballaggio.",

		taxableAmountBeforeTaxes: "Importo fiscale tasse escluse",

		beCompliantIsRequiredBy: "BeCompliant ha l'obbligo di presentare alle autorità un registro esaustivo di soggetti fatturati e spediti. Di conseguenza, l'aggiunta di un nuovo cliente richiede due passaggi da seguire:",
		createAnAddress: "creare un indirizzo",
		assignToBillOrShipp: "assegnare a 'fatturazione' e/o 'spedizione'.",
		pleaseSendUsAMessage: "Inviaci un messaggio alla sezione 'SUPPORTO', nel menu in alto a destra, se hai bisogno di aiuto.",

		presetInfo: "Informazione predefinita:",
		areaWithDefaultPresets: "area riservata alle preimpostazioni predefinite del codice NC introdotto. Non modificare se non conosci i codici doganali, le accise e le tasse. Qualsiasi modifica a questa sezione verrà esaminata manualmente prima di procedere con la spedizione. Se ritieni che quest'area non sia stata compilata correttamente e hai bisogno di supporto, inviaci un messaggio mediante la sezione 'SUPPORTO', nel menu in alto a destra.",

		paymentConfirmed: "Pagamento confermato. Puoi chiudere la pagina."
	},
}