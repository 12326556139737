export const UserNamespace = "user"

export const UserActionTypes = {
  GetUserCollection: "getUserCollection",
  GetUser: "getUser",
  CreateUser: "CreateUser",
  UpdateUser: "updateUser",
  ClearState: "clearState"
}

export const UserMutationTypes = {
  SetUserCollection: "setUserCollection",
  SetUser: "setUser",
  SetLoadingState: "setLoadingState",
  ClearState: "clearState"
}

export const IState = {
  loading: false,
  users: [],
  user: {},
  page: 1,
  limit: 25,
  total: 0,
}

export const IUser = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  eurotaxServiceValue: 0,
  accountState: true,
  phone: "",
  locale: "",
  shopName: "",
  createdAt: "",
  updateAt: ""
}