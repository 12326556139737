export default [
	{
		path: "/dashboard",
		name: "DashboardPage",
		component: () => import("../views/Admin/Dashboard/DashboardPage"),
		meta: {
			auth: true,
			userRole: ["ROLE_SUPER_ADMIN","ROLE_ADMIN", "ROLE_EUROTAX", "ROLE_CUSTOMER", "ROLE_CO_MANAGER"],
			layout: "Default"
		}
	},
	
]