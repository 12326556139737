export default {
  en: {
    languages: "Languages",
		country: "Country",
		currency: "Currency",
		currencyLetters :"Currency letters",
		defaultEurotaxServicePrice: "Default Eurotax service price (per unit)"
  },
  fr: {
    languages: "Langues",
		country: "Pays",
		currency: "Devise",
		currencyLetters :"Code devise",
		defaultEurotaxServicePrice: "Prix service Eurotax par défaut"
  },
	spa: {
    languages: "Idiomas",
		country: "País",
		currency: "Divida",
		currencyLetters :"Codigó divisa",
		defaultEurotaxServicePrice: "Tarifas servicio Eurotax (por defecto)"
  },
	it: {
    languages: "Lingue",
		country: "Paese",
		currency: "Valuta",
		currencyLetters :"Valuta in lettere",
		defaultEurotaxServicePrice: "Tariffa servizio Eurotax predefinita"
  },
};
